import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc, deleteDoc, collection, addDoc, getDocs, writeBatch } from 'firebase/firestore';
// import goalsImage from './goals.png';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const GoalsPageContainer = styled.div`
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ActionItemWrapper = styled.div`
  position: relative;
  margin-bottom: 30px;  // Increase bottom margin to make room for buttons
  &:hover .action-buttons {
    display: flex;
  }
`;
const ActionItemButtons = styled.div`
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  justify-content: flex-end;
  background-color: #f8f9fa;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;
const ActionItemButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  color: #4A90E2;
  &:hover {
    text-decoration: underline;
  }
`;
const ActionItemInput = styled.input`
  width: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #4A90E2;
  border-radius: 3px;
`;

const GameButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
  box-shadow: 3px 3px 0 #000;

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 5px 5px 0 #000;
    background-color: #f0f0f0;
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
    box-shadow: 1px 1px 0 #000;
    background-color: #e0e0e0;
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    box-shadow: none;
  }
`;
const SpinnerWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

// const Title = styled.h1`
//   font-size: 36px;
//   color: #333;
//   margin-bottom: 30px;
//   text-align: center;
// `;

const GoalInput = styled.input`
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #4A90E2;
  border-radius: 5px;
  margin-right: 10px;
`;

const GoalList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 500px;
`;


const GoalText = styled.span`
  flex-grow: 1;
  margin-right: 10px;
`;

const GoalItemWrapper = styled.li`
  position: relative;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  z-index: 0;
`;
const GoalAndActions = styled.div`
  position: relative;
`;

const GoalItem = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  font-size: 20px;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #4A90E2;
  position: relative;
  transform: translateY(0);
  margin-bottom: 5px;
  cursor: move;
  z-index: 1;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #4A90E2;
    opacity: 0.7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
const GoalActions = styled.div`
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 2;
  
  ${GoalAndActions}:hover & {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ActionItems = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
`;

const ActionItem = styled.li`
  background-color: #e6f2ff;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.3s ease;

  ${ActionItemWrapper}:hover & {
    border-radius: 5px 5px 0 0;
  }
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #4A90E2;
  transition: color 0.2s ease;

  &:hover {
    color: #357ABD;
  }
`;


const GoalsPage = () => {
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState('');
  const [editingGoal, setEditingGoal] = useState({ index: null, value: '' });
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();


// useEffect(() => {
//   const fetchGoals = async () => {
//     if (auth.currentUser) {
//       const goalsCollectionRef = collection(db, 'users', auth.currentUser.uid, 'goals');
//       const goalsSnapshot = await getDocs(goalsCollectionRef);
//       const fetchedGoals = goalsSnapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data(),
//         isPinned: doc.data().isPinned || false // Ensure isPinned is always defined
//       }));
//       setGoals(fetchedGoals);
//     }
//   };

//   fetchGoals();
// }, [auth.currentUser, db]);
useEffect(() => {
  const fetchGoals = async () => {
    if (auth.currentUser) {
      try {
        const goalsCollectionRef = collection(db, 'users', auth.currentUser.uid, 'goals');
        const goalsSnapshot = await getDocs(goalsCollectionRef);
        const fetchedGoals = goalsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          isPinned: doc.data().isPinned || false,
          order: doc.data().order || 0
        }));
        fetchedGoals.sort((a, b) => a.order - b.order);
        console.log('Fetched goals:', fetchedGoals);
        setGoals(fetchedGoals);
      } catch (error) {
        console.error("Error fetching goals:", error);
        // Optionally, set an error state here to display to the user
      }
    }
  };

  fetchGoals();
}, [auth.currentUser, db]);

const [loadingAmbition, setLoadingAmbition] = useState(null);
const [loadingActionItems, setLoadingActionItems] = useState(null);
// const newGoalObject = { text: newGoal.trim(), actionItems: [], isPinned: false };

const handlePinGoal = async (goalId) => {
  if (auth.currentUser) {
    const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
    const goalToUpdate = goals.find(g => g.id === goalId);
    const updatedIsPinned = !goalToUpdate.isPinned;
    
    // Update Firestore
    await updateDoc(goalDocRef, { isPinned: updatedIsPinned });
    
    // Update local state
    const updatedGoals = goals.map(g =>
      g.id === goalId ? { ...g, isPinned: updatedIsPinned } : g
    );
    setGoals(updatedGoals);
  }
};


const handleAddGoal = async () => {
  if (newGoal.trim() !== '' && auth.currentUser) {
    const newGoalObject = { 
      text: newGoal.trim(), 
      actionItems: [], 
      isPinned: false, 
      order: goals.length
    };
    const goalsCollectionRef = collection(db, 'users', auth.currentUser.uid, 'goals');
    const docRef = await addDoc(goalsCollectionRef, newGoalObject);
    const newGoalWithId = { id: docRef.id, ...newGoalObject };
    setGoals(prevGoals => [...prevGoals, newGoalWithId]);
    setNewGoal('');
    console.log('Added new goal:', newGoalWithId);

    // Automatically trigger the add action items function
    handleAddActionItems(docRef.id, newGoalWithId);
  }
};

// const handleAddGoal = async () => {
//   if (newGoal.trim() !== '' && auth.currentUser) {
//     const newGoalObject = { text: newGoal.trim(), actionItems: [], isPinned: false };
//     const goalsCollectionRef = collection(db, 'users', auth.currentUser.uid, 'goals');
//     const docRef = await addDoc(goalsCollectionRef, newGoalObject);
//     setGoals(prevGoals => [{ id: docRef.id, ...newGoalObject }, ...prevGoals]);
//     setNewGoal('');
//   }
// };
  // const handleAddGoal = async () => {
  //   if (newGoal.trim() !== '' && auth.currentUser) {
  //     const newGoalObject = { text: newGoal.trim(), actionItems: [] };
  //     const goalsCollectionRef = collection(db, 'users', auth.currentUser.uid, 'goals');
  //     const docRef = await addDoc(goalsCollectionRef, newGoalObject);
  //     setGoals([...goals, { id: docRef.id, ...newGoalObject }]);
  //     setNewGoal('');
  //   }
  // };

  const handleEditActionItem = async (goalId, index, newValue) => {
    if (newValue.trim() !== '' && auth.currentUser) {
      const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
      const updatedGoal = goals.find(g => g.id === goalId);
      const updatedActionItems = [...updatedGoal.actionItems];
      updatedActionItems[index] = newValue.trim();
  
      await updateDoc(goalDocRef, { actionItems: updatedActionItems });
  
      const updatedGoals = goals.map(g =>
        g.id === goalId ? { ...g, actionItems: updatedActionItems } : g
      );
      setGoals(updatedGoals);
      setEditingActionItem({ goalId: null, index: null, value: '' });
    }
  };

  const [editingActionItem, setEditingActionItem] = useState({ goalId: null, index: null, value: '' });
  
  const handleDragEnd = async (result) => {
    if (!result.destination) return;
  
    const items = Array.from(goals);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    // Update local state immediately
    setGoals(items);
  
    if (auth.currentUser) {
      try {
        const batch = writeBatch(db);
  
        items.forEach((goal, index) => {
          if (goal && goal.id) {
            const goalRef = doc(db, 'users', auth.currentUser.uid, 'goals', goal.id);
            batch.update(goalRef, { order: index });
          }
        });
  
        await batch.commit();
        console.log('Updated goal order in Firestore');
      } catch (error) {
        console.error("Error updating goal order:", error);
        // Optionally, revert the local state if the Firestore update fails
      }
    }
  };
  const handleDeleteActionItem = async (goalId, index) => {
    if (auth.currentUser) {
      const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
      const updatedGoal = goals.find(g => g.id === goalId);
      const updatedActionItems = updatedGoal.actionItems.filter((_, i) => i !== index);
  
      await updateDoc(goalDocRef, { actionItems: updatedActionItems });
  
      const updatedGoals = goals.map(g =>
        g.id === goalId ? { ...g, actionItems: updatedActionItems } : g
      );
      setGoals(updatedGoals);
    }
  };
  // const [actionItems, setActionItems] = useState({});
  const handleAddAmbition = async (goalId, goal) => {
    setLoadingAmbition(goalId);

    try {
      console.log('Sending goal for ambition:', goal.text);
      const response = await axios.post('https://us-central1-aisupertutor.cloudfunctions.net/addAmbition2', {
        goal: goal.text
      });
      
      console.log('Received response:', response.data);
      
      if (response.data && response.data.success) {
        const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
        await updateDoc(goalDocRef, { text: response.data.ambitiousGoal });
        
        const updatedGoals = goals.map(g => 
          g.id === goalId ? { ...g, text: response.data.ambitiousGoal } : g
        );
        setGoals(updatedGoals);
      }
       else {
        throw new Error(response.data.error || 'Failed to add ambition');
      }
    } catch (error) {
      console.error('Error adding ambition:', error.response?.data || error.message);
      alert('An error occurred while adding ambition. Please try again.');
    }
    finally {
      setLoadingAmbition(null);
    }
  };

  const handleAddActionItems = async (goalId, goal) => {
    setLoadingActionItems(goalId);
  
    try {
      console.log('Sending goal for action items:', goal.text);
      const response = await axios.post('https://us-central1-aisupertutor.cloudfunctions.net/addActionItems2', {
        goal: goal.text
      });
      
      console.log('Received response:', response.data);
      
      if (response.data && response.data.success) {
        const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
        await updateDoc(goalDocRef, { actionItems: response.data.actionItems });
        
        setGoals(prevGoals => prevGoals.map(g => 
          g.id === goalId ? { ...g, actionItems: response.data.actionItems } : g
        ));
      } else {
        throw new Error(response.data.error || 'Failed to add action items');
      }
    } catch (error) {
      console.error('Error adding action items:', error.response?.data || error.message);
      alert('An error occurred while adding action items. Please try again.');
    } finally {
      setLoadingActionItems(null);
    }
  };
  // const handleAddActionItems = async (goalId, goal) => {
  //   setLoadingActionItems(goalId);

  //   try {
  //     console.log('Sending goal for action items:', goal.text);
  //     const response = await axios.post('https://us-central1-aisupertutor.cloudfunctions.net/addActionItems2', {
  //       goal: goal.text
  //     });
      
  //     console.log('Received response:', response.data);
      
  //     if (response.data && response.data.success) {
  //       const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
  //       await updateDoc(goalDocRef, { actionItems: response.data.actionItems });
        
  //       const updatedGoals = goals.map(g => 
  //         g.id === goalId ? { ...g, actionItems: response.data.actionItems } : g
  //       );
  //       setGoals(updatedGoals);
  //     }
  //      else {
  //       throw new Error(response.data.error || 'Failed to add action items');
  //     }
  //   } catch (error) {
  //     console.error('Error adding action items:', error.response?.data || error.message);
  //     alert('An error occurred while adding action items. Please try again.');
  //   }finally{
  //     setLoadingActionItems(null);
  // }
  // };
  const handleEditGoal = async (id, newValue) => {
    if (newValue.trim() !== '' && auth.currentUser) {
      const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', id);
      await updateDoc(goalDocRef, { text: newValue.trim() });
      
      const updatedGoals = goals.map(goal => 
        goal.id === id ? { ...goal, text: newValue.trim() } : goal
      );
      setGoals(updatedGoals);
      setEditingGoal({ index: null, value: '' });
    }
  };
  
  const handleDeleteGoal = async (id) => {
    if (auth.currentUser) {
      const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', id);
      await deleteDoc(goalDocRef);
      
      const updatedGoals = goals.filter(goal => goal.id !== id);
      setGoals(updatedGoals);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (editingGoal.index !== null) {
        handleEditGoal(editingGoal.index, editingGoal.value);
      } else {
        handleAddGoal();
      }
    }
  };

  return (
    <GoalsPageContainer>
      <GameButton onClick={() => navigate('/')} style={{ position: 'absolute', top: '10px', left: '10px' }}>&larr;</GameButton>
      <div>
        {/* <img src={goalsImage} alt="Goals" style={{ width: '30px', transform: 'rotate(14deg)' }} /> */}
      </div>
      {/* <Title>How do you envision your super self?</Title> */}
      <img src="/supertutorlogo.png" alt="Supertutor" style={{ height: '100px', marginTop: '50px', marginRight: '10px' }} />
      <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '550px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>

Tell me your goals and i'll give you action items.
</h2>
<div>
  <GoalInput
    type="text"
    value={newGoal}
    onChange={(e) => setNewGoal(e.target.value)}
    onKeyPress={handleKeyPress}
    placeholder="What are your biggest goals?"
    disabled={loadingActionItems !== null}
  />
  <GameButton onClick={handleAddGoal} disabled={loadingActionItems !== null}>
    Add Goal
  </GameButton>
  {loadingActionItems !== null && (
    <SpinnerWrapper>
      <ThreeDots color="#4A90E2" height={20} width={20} />
    </SpinnerWrapper>
  )}
</div>
      <DragDropContext onDragEnd={handleDragEnd}>
  <Droppable droppableId="goals-list">
    {(provided) => (
      <GoalList
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        {goals.map((goal, index) => (
          <Draggable key={goal.id} draggableId={goal.id} index={index}>
            {(provided, snapshot) => (
              <GoalItemWrapper
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  zIndex: snapshot.isDragging ? 1000 : 'auto'
                }}
              >
                      <GoalAndActions>
                        <GoalItem>
                          {editingGoal.index === goal.id ? (
                            <GoalInput
                              type="text"
                              value={editingGoal.value}
                              onChange={(e) => setEditingGoal({ ...editingGoal, value: e.target.value })}
                              onKeyPress={handleKeyPress}
                              onBlur={() => handleEditGoal(goal.id, editingGoal.value)}
                              autoFocus
                            />
                          ) : (
                            <GoalText>{goal.text}</GoalText>
                          )}
                        </GoalItem>
                        <GoalActions>
                          <ActionButton onClick={() => handlePinGoal(goal.id)}>
                            {goal.isPinned ? 'Unpin' : 'Pin'}
                          </ActionButton>
                          <ActionButton onClick={() => handleAddAmbition(goal.id, goal)} disabled={loadingAmbition === goal.id}>
                            Add Ambition
                            {loadingAmbition === goal.id && (
                              <SpinnerWrapper>
                                <ThreeDots color="#4A90E2" height={20} width={20} />
                              </SpinnerWrapper>
                            )}
                          </ActionButton>
                          <ActionButton onClick={() => handleAddActionItems(goal.id, goal)} disabled={loadingActionItems === goal.id}>
                            Add Action Items
                            {loadingActionItems === goal.id && (
                              <SpinnerWrapper>
                                <ThreeDots color="#4A90E2" height={20} width={20} />
                              </SpinnerWrapper>
                            )}
                          </ActionButton>
                          <ActionButton onClick={() => setEditingGoal({ index: goal.id, value: goal.text })}>Edit</ActionButton>
                          <ActionButton onClick={() => handleDeleteGoal(goal.id)}>Delete</ActionButton>
                        </GoalActions>
                      </GoalAndActions>
                      {goal.actionItems && goal.actionItems.length > 0 && (
                        <>
                          <h4>Action Items:</h4>
                          <ActionItems>
                            {goal.actionItems.map((item, itemIndex) => (
                              <ActionItemWrapper key={itemIndex}>
                                {editingActionItem.goalId === goal.id && editingActionItem.index === itemIndex ? (
                                  <ActionItemInput
                                    value={editingActionItem.value}
                                    onChange={(e) => setEditingActionItem({ ...editingActionItem, value: e.target.value })}
                                    onBlur={() => handleEditActionItem(goal.id, itemIndex, editingActionItem.value)}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        handleEditActionItem(goal.id, itemIndex, editingActionItem.value);
                                      }
                                    }}
                                    autoFocus
                                  />
                                ) : (
                                  <ActionItem>{item}</ActionItem>
                                )}
                                <ActionItemButtons className="action-buttons">
                                  <ActionItemButton onClick={() => setEditingActionItem({ goalId: goal.id, index: itemIndex, value: item })}>
                                    Edit
                                  </ActionItemButton>
                                  <ActionItemButton onClick={() => handleDeleteActionItem(goal.id, itemIndex)}>
                                    Delete
                                  </ActionItemButton>
                                </ActionItemButtons>
                              </ActionItemWrapper>
                            ))}
                          </ActionItems>
                        </>
                      )}
                           </GoalItemWrapper>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </GoalList>
    )}

        </Droppable>
      </DragDropContext>
    </GoalsPageContainer>
  );
};

export default GoalsPage;