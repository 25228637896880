
import React, { useState, useEffect, useRef, useCallback } from 'react'; // Add useRef import
import { useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';
import ColorPickerButton from './ColorPickerButton';

 import { auth, firestore, storage } from './firebase';
// import { MetaphorSettingsPopup } from './MetaphorSettingsPopup';
import styled, { keyframes } from 'styled-components';
import { getAuth } from 'firebase/auth';
import logo from './logo.png'; // Assuming you have star2.png in the same folder
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { getFirestore, where, collection, addDoc, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { query, orderBy, limit, getDocs, onSnapshot } from 'firebase/firestore';
import UpgradePopup from './UpgradePopup'; // Add this import
//import LoadingAnimation from './LoadingAnimation';
import 'react-toggle/style.css'; // Import the CSS for the toggle component
// import QuizGame from './QuizGame';
import { MathJaxContext } from 'better-react-mathjax';
 import debounce from 'lodash/debounce'; // You may need to install lodash
import axios from 'axios';

// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { MathJaxContext, MathJax } from 'better-react-mathjax';
import ReactMarkdown from 'react-markdown';
import './CodeBlock.css';
import CodeBlock from './CodeBlock';
import TreasureChestPopup from './TreasureChestPopup';
import './LoadingAnimation2.css';
//import Dino from './Dino';
import { signOut } from 'firebase/auth';
import AboutPopup from './AboutPopup';
import ManageAccountPopup from './ManageAccountPopup';
// import multiplayer from './multiplayer.png'; // Assuming you have star1.png in the same folder
import star1 from './star1.png'; // Assuming you have star1.png in the same folder
import q from './q.png'; // Assuming you have star1.png in the same folder
import s from './s.png'; // Assuming you have star1.png in the same folder
import met from './met.png'; // Assuming you have star1.png in the same folder
import R from './R.png'; // Assuming you have star1.png in the same folder
import B from './B.png'; // Assuming you have star1.png in the same folder
// import M from './M.png'; // Assuming you have star1.png in the same folder
import Me from './Me.png'; // Assuming you have star1.png in the same folder
import imagine from './imagine.png'; // Assuming you have star1.png in the same folder
import goals from './goals.png'; // Assuming you have star1.png in the same folder
import gametime from './gametime.png'; // Assuming you have star1.png in the same folder

// import star2 from './star2.png'; // Assuming you have star2.png in the same folder
import { useParams, useLocation } from 'react-router-dom';
import MySuperAnswers from './MySuperAnswers';
import history from './history.png'; // Assuming you have star1.png in the same folder
import newchat from './newchat.png'; // Assuming you have star1.png in the same folder

 import { ResizableBox } from 'react-resizable';

 const ImagePreview = ({ imageUrl, onClose }) => (
  <div 
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}
    onClick={onClose}
  >
    <img 
      src={imageUrl} 
      alt="Preview" 
      style={{
        maxWidth: '90%',
        maxHeight: '90%',
        objectFit: 'contain',
      }}
    />
  </div>
);

const PinnedGoalsContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
padding: 10px;
background-color: transparent;
`;

const PinnedGoal = styled.div`
background-color: #fff;
border: 1px solid #4A90E2;
border-radius: 5px;
padding: 5px 10px;
margin: 5px;
font-size: 14px;
position: relative;

&:hover .goal-actions {
  display: flex;
}
`;

const GoalActions = styled.div`
display: none;
position: absolute;
top: 100%;
left: 0;
right: 0;
background-color: #fff;
border: 1px solid #4A90E2;
border-top: none;
border-radius: 0 0 5px 5px;
padding: 5px;
`;

const GoalAction = styled.button`
background: none;
border: none;
color: #4A90E2;
cursor: pointer;
margin: 0 5px;
font-size: 12px;

&:hover {
  text-decoration: underline;
}
`;



function FirebaseImage({ filePath, alt, style }) {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchImageUrl = async () => {
      const storage = getStorage();
      try {
        const imageUrl = await getDownloadURL(ref(storage, filePath));
        if (isMounted) {
          setUrl(imageUrl);
        }
      } catch (error) {
        console.error("Error fetching image URL:", error);
      }
    };

    if (!url) {
      fetchImageUrl();
    }

    return () => {
      isMounted = false;
    };
  }, [filePath, url]);

  if (!url) {
    return <div>Loading...</div>;
  }

  return (
    <img
      src={url}
      alt={alt}
      style={style}
      onError={(e) => {
        console.error("Error loading image:", e);
      }}
    />
  );
}

function DimensionPopup({ onSelect, onClose }) {
  const dimensions = [
    { name: 'Vertical', size: '1024x1792', preview: '▯' },
    { name: 'Horizontal', size: '1792x1024', preview: '▭' },
    { name: 'Square', size: '1024x1024', preview: '▢' },
  ];

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'white',
      padding: '30px',
      borderRadius: '15px',
      boxShadow: '0 0 20px rgba(0,0,0,0.2)',
      zIndex: 1000,
      width: '300px',
    }}>
      <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Select Image Dimension</h3>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {dimensions.map((dim) => (
          <button
            key={dim.size}
            onClick={() => onSelect(dim.size)}
            style={{
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '5px',
              background: '#f0f0f0',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>{dim.name} ({dim.size})</span>
            <span style={{ fontSize: '24px' }}>{dim.preview}</span>
          </button>
        ))}
      </div>
      <button 
          onClick={onClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          ×
        </button>
 
    </div>
  );
}

function AskQuestion() {
  const createTitle = (content, maxSentences = 2) => {
    if (!content) return 'Untitled';
    const sentences = content.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0);
    const titleSentences = sentences.slice(0, maxSentences);
    let title = titleSentences.join('. ');
    if (sentences.length > maxSentences) {
      title += '...';
    }
    if (!title.endsWith('.') && !title.endsWith('!') && !title.endsWith('?')) {
      title += '.';
    }
    return title;
  };
 
  const [isMathJaxLoaded, setIsMathJaxLoaded] = useState(false);
  // const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const [conversationHistory, setConversationHistory] = useState([]);
  // const [imageDimension, setImageDimension] = useState(null);
  const [showDimensionPopup, setShowDimensionPopup] = useState(false);


  useEffect(() => {
    const checkMathJax = () => {
      if (window.MathJax && window.MathJax.typesetPromise) {
        setIsMathJaxLoaded(true);
        setIsLoading(false);
      } else {
        setTimeout(checkMathJax, 100);
      }
    };
  
    checkMathJax();
  }, []);



  const typesetMathJax = useCallback(() => {
    if (window.MathJax && window.MathJax.typesetPromise) {
      window.MathJax.typesetPromise([document.body]).then(() => {
        console.log('MathJax typesetting complete');
      }).catch((err) => console.log('MathJax typesetting error:', err));
    } else {
      console.log('MathJax not available, skipping typeset');
    }
  }, []);


  const typeset = useCallback(() => {
    if (window.MathJax && window.MathJax.typesetPromise) {
      window.MathJax.typesetPromise([document.body]).then(() => {
        console.log('MathJax typesetting complete');
      }).catch((err) => console.log('MathJax typesetting error:', err));
    } else {
      console.log('MathJax not available, skipping typeset');
    }
  }, []);
  // const scrollToBottom = () => {
  //   const chatContainer = document.getElementById('chat-container');
  //   if (chatContainer) {
  //     chatContainer.scrollTop = chatContainer.scrollHeight;
  //   }
  // };
  // const scrollToBottom = () => {
  //   const chatContainer = document.getElementById('chat-container');
  //   if (chatContainer) {
  //     chatContainer.scrollTop = chatContainer.scrollHeight;
  //   }
  // };

  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver(() => {
  //     // Handle resize without scrolling
  //     console.log('Resize detected');
  //   });
  //   if (conversationContainerRef.current) {
  //     resizeObserver.observe(conversationContainerRef.current);
  //   }
  //   return () => resizeObserver.disconnect();
  // }, []);

  const handleQuizClick = () => {
    const textAreaContent = question.trim();
    const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
  
    if (textAreaContent !== '' || lastAnswer) {
      // If there's a valid prompt or conversation history, show the popup
      setShowQuizPopup(true);
    } else {
      // If there's no valid input, show an error message
      setError('Please type a question or topic in the textarea to generate a quiz.');
      // Clear the error message after 3 seconds
      setTimeout(() => setError(''), 3000);
    }
  };
  const [quizData, setQuizData] = useState(null);
  const [pinnedGoals, setPinnedGoals] = useState([]);
  useEffect(() => {
    const fetchPinnedGoals = async () => {
      const auth = getAuth();
      const db = getFirestore();
      if (auth.currentUser) {
        const goalsCollectionRef = collection(db, 'users', auth.currentUser.uid, 'goals');
        const q = query(goalsCollectionRef, where("isPinned", "==", true));
        const querySnapshot = await getDocs(q);
        const fetchedPinnedGoals = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPinnedGoals(fetchedPinnedGoals);
      }
    };
  
    fetchPinnedGoals();
  }, []);

  const handleMetaphorClick = () => {
    const textAreaContent = question.trim();
    const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
  
    if (textAreaContent !== '' || lastAnswer) {
      // If there's a valid prompt or conversation history, show the popup
      setShowMetaphorCategoryPopup(true);
    } else {
      // If there's no valid input, show an error message
      setError('Please type a question or topic in the textarea to generate a metaphor.');
      // Clear the error message after 3 seconds
      setTimeout(() => setError(''), 3000);
    }
  };

const handleUnpinGoal = async (goalId) => {
  const auth = getAuth();
  const db = getFirestore();
  if (auth.currentUser) {
    const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
    await updateDoc(goalDocRef, { isPinned: false });
    setPinnedGoals(pinnedGoals.filter(g => g.id !== goalId));
  }
};

const handleEditPinnedGoal = async (goalId, newText) => {
  const auth = getAuth();
  const db = getFirestore();
  if (auth.currentUser) {
    const goalDocRef = doc(db, 'users', auth.currentUser.uid, 'goals', goalId);
    await updateDoc(goalDocRef, { text: newText });
    setPinnedGoals(pinnedGoals.map(g => g.id === goalId ? { ...g, text: newText } : g));
  }
};


const handleImageClick = () => {
  const textAreaContent = question.trim();
  const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;

  if (textAreaContent !== '' || lastAnswer) {
    // If there's a valid prompt or conversation history, show the dimension popup
    setShowDimensionPopup(true);
  } else {
    // If there's no valid input, show an error message
    setError('Please type a question or topic in the textarea to generate an image.');
    // Clear the error message after 3 seconds
    setTimeout(() => setError(''), 3000);
  }
};

  //  const [autoScroll, setAutoScroll] = useState(true);
const conversationContainerRef = useRef(null);
const [previewImage, setPreviewImage] = useState(null);
const handleImageClick2 = (imageUrl) => {
  setPreviewImage(imageUrl);
};

const [showQuizPopup, setShowQuizPopup] = useState(false);
const [numQuestions, setNumQuestions] = useState(3);

const QuizPopup = ({ onClose, onSubmit }) => (
  <div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    borderRadius: '10px',
    padding: '30px', // Increased padding for better spacing
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Darkened shadow for better contrast
    zIndex: 1000,
    width: 'fit-content', // Improved width handling
    maxWidth: '400px', // Set a maximum width for consistency
    textAlign: 'center' // Centered text alignment
  }}>
    <h3 style={{
      marginBottom: '20px', // Adjusted bottom margin for better spacing
      color: '#333', // Changed text color for better readability
    }}>Enter the number of quiz questions (1-10):</h3>
       <button 
          onClick={onClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
              zIndex: 1000, // Add this line

          }}
        >
          ×
        </button>
    <input
      type="number"
      min="1"
      max="10"
      value={numQuestions}
      onChange={(e) => setNumQuestions(parseInt(e.target.value))}
      style={{
        width: 'calc(100% - 40px)', // Avoid touching the edges and keep within fixed width
        padding: '10px 20px', // Better padding for more space
        margin: '10px 20px', // Adjust left and right margins
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px', // Increased the font size
        outline: 'none'
      }}
    />
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <button onClick={onSubmit} style={{
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        background: '#32cd32',
        color: 'white',
        cursor: 'pointer',
        marginRight: '10px',
        fontSize: '16px'
      }}>
        Submit
      </button>
   
    </div>
    {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
  </div>
);


const [isLoading, setIsLoading] = useState(true);

  const [showSlideover, setShowSlideover] = useState(false);
  const [clearTooltip, setClearTooltip] = useState(false);
  // const [displayCount, setDisplayCount] = useState(20);
  const [displayCount] = useState(20);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState('');
  const [quizLoading, setQuizLoading] = useState(false);


  // const handleChoiceClick = (choice) => {
  //   setSelectedChoice(choice);
  //   setShowResult(true);
  
  //   if (choice === quizData[currentQuestionIndex].correctAnswer) {
  //     setScore(score + 1);
  //   }
  // };

  const handleChoiceClick = (choice) => {
    setSelectedChoice(choice);
    setShowResult(true);
  
    if (choice === quizData[currentQuestionIndex].correctAnswer) {
      setScore(score + 1);
      // Trigger confetti explosion
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 1001, // Match the z-index of the container
        disableForReducedMotion: true // Accessibility consideration
      });
    }
  };

  
  const handleNextQuestion = () => {
    if (!showResult) return;

    setShowResult(false);
    setSelectedChoice('');

    if (currentQuestionIndex === quizData.length - 1) {
      setShowQuizGame(false);
      // Handle quiz completion (e.g., save score, show message)
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
 
  const location = useLocation();
  // const conversationId = searchParams.get('conversationId');
  const { conversationId: urlParamConversationId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const searchParamConversationId = searchParams.get('conversationId');

  const conversationId = urlParamConversationId || searchParamConversationId;
  const [memeTooltip, setMemeTooltip] = useState(false);
  const [memeLoading, setMemeLoading] = useState(false);

  const [audioLoadingStates, setAudioLoadingStates] = useState({});

  // const handleManualScroll = useCallback(() => {
  //   setAutoScrollEnabled(false);
  // }, []);

  const [imageTooltip, setImageTooltip] = useState(false);
  const [rewardsTooltip, setRewardsTooltip] = useState(false); // Add this line

  const [newChatTooltip] = useState(false);

  // const [sourcesLoading, setSourcesLoading] = useState(false);
  // const [mnemonicLoading, setMnemonicLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [goalsTooltip, setGoalsTooltip] = useState(false); // Add this line

  const Spinner = () => (
    <div
      className="spinner"
      style={{
        width: '16px',
        height: '16px',
        border: '6px solid rgba(0, 0, 0, 0.1)',
        borderLeftColor: '#09f',
      }}
    ></div>
  );

  const mathJaxConfig = {
    tex: {
      packages: ['base', 'ams', 'noerrors', 'noundefined'],
      inlineMath: [['$', '$'], ['\\(', '\\)']],
      displayMath: [['$$', '$$'], ['\\[', '\\]']],
      processEscapes: true,
      processEnvironments: true,
      autoload: {
        color: [],
        colorV2: ['color']
      },
      tags: 'ams'
    },
    options: {
      ignoreHtmlClass: 'tex2jax_ignore',
      processHtmlClass: 'tex2jax_process',
      renderActions: {
        addMenu: []
      }
    },
    startup: {
      typeset: false
    },
    svg: {
      fontCache: 'global'
    }
  };
  

  const contentChangeRef = useRef(false);
  
  useEffect(() => {
    if (isMathJaxLoaded) {
      setIsLoading(false);
    }
  }, [isMathJaxLoaded]);


useEffect(() => {
  const typeset = () => {
    if (window.MathJax) {
      window.MathJax.typesetPromise().then(() => {
        console.log('MathJax typesetting complete');
        contentChangeRef.current = true;
      }).catch((err) => console.log(err));
    } else {
      contentChangeRef.current = true;
    }
  };

  typeset();
}, [conversationHistory]);






useEffect(() => {
  if (conversationHistory.length > 0) {
    const lastMessage = conversationHistory[conversationHistory.length - 1];
    if (lastMessage.type === 'answer') {
      typeset();
    }
  }
}, [conversationHistory, typeset]);

useEffect(() => {
  console.log("quizData in AskQuestion updated:", quizData);
}, [quizData]);


// useEffect(() => {
//   const typeset = () => {
//     if (window.MathJax) {
//       window.MathJax.typesetPromise().then(() => {
//         console.log('MathJax typesetting complete');
//         contentChangeRef.current = true;
//         if (autoScrollEnabled) {
//           debouncedScroll();
//         }
//       }).catch((err) => console.log(err));
//     } else {
//       contentChangeRef.current = true;
//       if (autoScrollEnabled) {
//         debouncedScroll();
//       }
//     }
//   };

//   typeset();
// }, [conversationHistory, debouncedScroll, autoScrollEnabled]);
useEffect(() => {
  const typeset = () => {
    if (window.MathJax) {
      window.MathJax.typesetPromise().then(() => {
        console.log('MathJax typesetting complete');
        contentChangeRef.current = true;
      }).catch((err) => console.log(err));
    } else {
      contentChangeRef.current = true;
    }
  };

  typeset();
}, [conversationHistory]);

useEffect(() => {
  const typeset = debounce(() => {
    if (window.MathJax && window.MathJax.typesetPromise) {
      window.MathJax.typesetPromise([document.body]).then(() => {
        console.log('MathJax typesetting complete');
      }).catch((err) => console.log('MathJax typesetting error:', err));
    } else {
      console.log('MathJax not available, skipping typeset');
    }
  }, 500);

  if (isMathJaxLoaded && conversationHistory.length > 0) {
    typeset();
  }

  return () => typeset.cancel();
}, [isMathJaxLoaded, conversationHistory]);

  
  const processContent = (content) => {
    // Split the content into code and non-code blocks
    const blocks = content.split(/(```[\s\S]*?```)/);
    
    return blocks.map((block, index) => {
      if (index % 2 === 1) {
        // It's a code block
        const [language, ...codeLines] = block.slice(3, -3).split('\n');
        const code = codeLines.join('\n').trim();
        return {
          type: 'code',
          content: { language: language.trim(), code: code }
        };
      } else {
        // For non-code blocks, apply the formatting
        let processedBlock = block;
  
        // Remove HTML tags from the content
        processedBlock = processedBlock.replace(/<\/?[^>]+(>|$)/g, "");
  
        // Process LaTeX blocks
        processedBlock = processedBlock.replace(/\\\[([\s\S]*?)\\\]/g, (match) => {
          return `<div class="math-block" style="text-align: left; margin: 10px 0;">${match}</div>`;
        });
  
        // Process inline LaTeX
        processedBlock = processedBlock.replace(/\\\(([\s\S]*?)\\\)/g, (match) => {
          return `<span class="math-inline" style="text-align: left;">${match}</span>`;
        });
  
        // Handle dollar sign delimited LaTeX
        processedBlock = processedBlock.replace(/\$(.*?)\$/g, (match, p1) => {
          return `<span class="math-inline" style="text-align: left;">\\(${p1}\\)</span>`;
        });
  
        // Handle double stars for bold text
        processedBlock = processedBlock.replace(/\*\*(.*?)\*\*/g, (match, p1) => {
          return `<b>${p1.trim()}</b>`;
        });
  
        // Handle triple hash for larger text (as a heading)
        processedBlock = processedBlock.replace(/^### (.*?)$/gm, (match, p1) => {
          return `<h2 style="font-size: 1.5em; font-weight: bold; margin: 20px 0 10px;">${p1.trim()}</h2>`;
        });
  
        // Handle quadruple hash for larger text (as a heading)
        processedBlock = processedBlock.replace(/^#### (.*?)$/gm, (match, p1) => {
          return `<h2 style="font-size: 1.5em; font-weight: bold; margin: 20px 0 10px;">${p1.trim()}</h2>`;
        });
  
        // Handle numbered lists
        processedBlock = processedBlock.replace(/^\d+\.\s+(.+)$/gm, (match, p1) => {
          return `<li>${p1.trim()}</li>`;
        });
  
        // Wrap numbered lists in <ol> tags
        processedBlock = processedBlock.replace(/((?:<li>.*<\/li>\n?)+)/g, (match) => {
          return `<ol style="margin-left: 20px; margin-bottom: 10px;">${match}</ol>`;
        });
  
        // Handle all bullet points, regardless of indentation level
        processedBlock = processedBlock.replace(/^(\s*)(•|-|o)\s+(.+)$/gm, (match, indent, bullet, content) => {
          return `<li>${content.trim()}</li>`;
        });
  
        // Wrap all list items in a single <ul> tag
        processedBlock = processedBlock.replace(/((?:<li>.*<\/li>\n?)+)/g, (match) => {
          return `<ul style="list-style-type: disc; padding-left: 20px; margin-left: 0;">${match}</ul>`;
        });
  
        // Add line breaks after colons that follow bold text or headings
        processedBlock = processedBlock.replace(/(<\/b>|<\/h2>):\s*/g, '$1:<br>');
  
        // Add paragraph breaks
        processedBlock = processedBlock.replace(/\n\n/g, '</p><p>');
        processedBlock = `<p>${processedBlock}</p>`;
  
        return {
          type: 'text',
          content: processedBlock
        };
      }
    });
  };

  // To this:
useEffect(() => {
  if (isMathJaxLoaded && conversationHistory.length > 0) {
    window.MathJax.typesetPromise().then(() => {
      console.log('MathJax typesetting complete');
    }).catch((err) => console.log('MathJax typesetting error:', err));
  }
}, [isMathJaxLoaded, conversationHistory]);

  useEffect(() => {
    const fetchConversationMessages = async () => {
      if (auth.currentUser && conversationId) {
        const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', conversationId, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'asc'));
        const querySnapshot = await getDocs(q);
        const fetchedMessages = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setConversationHistory(fetchedMessages);
        setCurrentConversationId(conversationId);
  
        // Update the conversation title
        const conversationRef = doc(firestore, 'users', auth.currentUser.uid, 'conversations', conversationId);
        const conversationDoc = await getDoc(conversationRef);
        if (conversationDoc.exists()) {
          const conversationData = conversationDoc.data();
          const firstMessage = fetchedMessages.length > 0 ? fetchedMessages[0].content : '';
          const newTitle = createTitle(conversationData.title || firstMessage);
          if (newTitle !== conversationData.title) {
            await updateDoc(conversationRef, { title: newTitle });
          }
        }
  
        if (isMathJaxLoaded) {
          typesetMathJax();
        }
      }
    };
    fetchConversationMessages();
  }, [conversationId, isMathJaxLoaded, typesetMathJax]);

  
  
useEffect(() => {
  const fetchStreak = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setStreak(userData.streak || 0);
      }
    }
  };

  fetchStreak();
}, []);


const [currentAudio, setCurrentAudio] = useState(null);
const [showManageAccountPopup, setShowManageAccountPopup] = useState(false);

const handleManageAccountPopupToggle = () => {
  setShowManageAccountPopup(!showManageAccountPopup);
};

//const [songLoading, setSongLoading] = useState(false);

// const [audioTooltip, setAudioTooltip] = useState(false);
const [gameTooltip, setGameTooltip] = useState(false); // Add this line
const [playingAudioIndex, setPlayingAudioIndex] = useState(null);
const [streak, setStreak] = useState(0);
const [memeTemplates, setMemeTemplates] = useState([]);
const handleStartGame = () => {
  navigate('/partygame');
};
const handleStartGoals = () => {
  navigate('/goals');
};
// const [gameTooltip, setGameTooltip] = useState(false);

const [quizGameTooltip] = useState(false);
// const [MetaphorTooltip] = useState(false);
const [biggerVisionTooltip, setBiggerVisionTooltip] = useState(false);
// const [mnemonicTooltip] = useState(false);
// const [sourcesTooltip] = useState(false);
const [storyContent, setStoryContent] = useState('');
const [currentConversationId, setCurrentConversationId] = useState(null);


const [showStorytimePopup, setShowStorytimePopup] = useState(false);
// const [audioLoading, setAudioLoading] = useState(false);
const [showMenu, setShowMenu] = useState(false);
const [questionsAsked, setQuestionsAsked] = useState(false);


const handleMenuToggle = () => {
  setShowMenu(!showMenu);
};
// const [rhymeTooltip] = useState(false);

// const handleStorytimeClick = () => {
//   setShowStorytimePopup(true);
// };
const handleStorytimeClick = () => {
  const textAreaContent = question.trim();
  const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;

  if (textAreaContent !== '' || lastAnswer) {
    // If there's a valid prompt or conversation history, show the popup
    setShowStorytimePopup(true);
  } else {
    // If there's no valid input, show an error message
    setError('Please type a question or topic in the textarea to generate a story.');
    // Clear the error message after 3 seconds
    setTimeout(() => setError(''), 3000);
  }
};

const [audioGenerated, setAudioGenerated] = useState({});

async function handleGenerateAudio(content, itemIndex) {
  if (currentAudio) {
    const audio = new Audio(currentAudio);
    audio.pause();
    setCurrentAudio(null);
  }
  setPlayingAudioIndex(null);
  
  // Set loading state for this specific item
  setAudioLoadingStates(prevStates => ({ ...prevStates, [itemIndex]: true }));

  try {
    const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/convertTextToSpeech', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text: content,
        uid: auth.currentUser.uid,
      }),
    });

    if (response.ok) {
      const arrayBuffer = await response.arrayBuffer();
      const audioBlob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);
      setConversationHistory((prevHistory) => [
        ...prevHistory.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, audioUrl };
          }
          return item;
        }),
      ]);
      setAudioGenerated((prevState) => ({ ...prevState, [itemIndex]: true }));
    } else {
      console.error('Error generating audio:', response.statusText);
      setError('Failed to generate audio. Please try again.');
    }
  } catch (error) {
    console.error('Error generating audio:', error);
    setError('An error occurred while generating audio. Please try again.');
  } finally {
    // Clear loading state for this specific item
    setAudioLoadingStates(prevStates => ({ ...prevStates, [itemIndex]: false }));
  }
}

useEffect(() => {
  const audioElements = document.querySelectorAll('audio');
  audioElements.forEach((audio) => audio.pause());
  setPlayingAudioIndex(null);
  setCurrentAudio(null);
}, [conversationHistory.length]);


      useEffect(() => {
        const baseMemeTemplates = [
          { id: '61579', name: 'One Does Not Simply', format: 'One does not simply\\n{text}' },
          { id: '178591752', name: 'Tuxedo Winnie The Pooh', format: '{text}\\n{text}' },
          { id: '100947', name: 'Matrix Morpheus', format: 'What if I told you\\n{text}' },
          { id: '101511', name: 'Spongebob smirk', format: 'When you {text}\\n and {text}' },
          { id: '563423', name: 'That would be great', format: '{text} \\n that would be great.' },

          // { id: '14371066', name: 'Star Wars Yoda', format: '{text} or {text}. There is no {text}' },
          { id: '14371066', name: 'Star Wars Yoda', format: '{text} we must.' },

          // { id: '135256802', name: 'epic handshake', format: '{text} then\\n{text} now' },
          { id: '61532', name: 'The Most Interesting Man In The World', format: "I don't always {text}\\nBut when I do, {text}" },
          // { id: '61556', name: 'Grandma Finds The Internet', format: '{text}' },
          // { id: '135256802', name: 'Epic Handshake', format: '{text} then\\n{text} now' },
          // { id: '61520', name: 'Futurama Fry', format: 'Not sure if {text} or {text}' },
          // { id: '102156234', name: 'Mocking Spongebob', format: '{text}\\n{text}' },
          //{ id: '110163934', name: 'I Bet Hes Thinking About Other Women', format: 'I bet hes thinking about other women\\n{text}' },
          { id: '922147', name: 'Laughing Men In Suits', format: 'And then I said\n{text}' },
          // { id: '40945639', name: 'Dr Evil Laser', format: '{text}' },
          { id: '21604248', name: 'Mugatu So Hot Right Now', format: '{text}\\nSO HOT RIGHT NOW' },
          // { id: '112126428', name: 'Distracted Boyfriend', format: '{text}\n{text}\n{text}' },

        ];
      
        const brocMemeTemplates = [
          { id: '5496396', name: 'Leonardo DiCaprio Cheers', format: '{text}\\n{text}' },
          { id: '181913649', name: 'Drake Hotline Bling', format: '{text}\\n{text}' },
        ];
      
        const moonMemeTemplates = [
          { id: '101470', name: 'Ancient Aliens', format: '{text}\\n{text}' },
        ];
      
        if (streak >= 20) {
          setMemeTemplates([...baseMemeTemplates, ...brocMemeTemplates, ...moonMemeTemplates]);
        } else if (streak >= 10) {
          setMemeTemplates([...baseMemeTemplates, ...brocMemeTemplates]);
        } else {
          setMemeTemplates(baseMemeTemplates);
        }
      }, [streak]);

            const [showTreasureChestPopup, setShowTreasureChestPopup] = useState(false);
       

    const handleClearConversation = () => {
      setConversationHistory([]);
      setQuestionsAsked(false); // Add this line
      setCurrentConversationId(null); // Add this line to create a new conversation
      navigate('/'); // Add this line to remove the conversationId from the URL

    };

    const [imageLoading, setImageLoading] = useState(false);

    const [userName, setUserName] = useState('');

    const [showMetaphorCategoryPopup, setShowMetaphorCategoryPopup] = useState(false);

    // In AskQuestion.js
const getLastSuperAnswer = async () => {
  if (auth.currentUser) {
    const q = query(
      collection(firestore, 'users', auth.currentUser.uid, 'superAnswers'),
      orderBy('timestamp', 'desc'),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
    }
  }
  return null;
};
const [showMemeTemplatePopup, setShowMemeTemplatePopup] = useState(false);


// const MemeTemplatePopup = ({ onClose, onSelect, memeTemplates, memeText }) => {
  const MemeTemplatePopup = ({ onClose, onSelect, memeTemplates, memeText }) => {
    // Function to handle clicks outside the popup to close it
    const handleOutsideClick = (event) => {
      if (event.target.className === 'popup-overlay') {
        onClose();
      }
    };
  
    return (
      <div
        className="popup-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
        onClick={handleOutsideClick}
      >
        <div
          className="popup-content"
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            maxWidth: '400px',
            width: '100%',
            position: 'relative', // Needed for positioning the close button
          }}
        >
      <button 
          onClick={onClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          ×
        </button>
          <h3>Select Meme Template</h3>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {memeTemplates.map((template) => (
              <li key={template.id} style={{ marginBottom: '10px' }}>
                <button
                  onClick={() => {
                    onSelect(template, memeText);
                    onClose();
                  }}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#f0f0f0',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  {template.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

    const [showAboutPopup, setShowAboutPopup] = useState(false);
    const handleAboutPopupToggle = () => {
      setShowAboutPopup(!showAboutPopup);
    };

    const [selectedCharacter, setSelectedCharacter] = useState('supertutor');


    const scrollToBottom = () => {
      if (conversationContainerRef.current) {
        conversationContainerRef.current.scrollTop = conversationContainerRef.current.scrollHeight;
      }
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [conversationHistory]); // This will trigger scrollToBottom whenever conversationHistory changes
  

    const metaphorCategories = [
      { id: 'choose_your_own', name: 'Choose Your Own', emoji: '🎁' },
      { id: 'basketball', name: 'Superheros', emoji: '🦸‍♂️' },
      { id: 'love_island', name: 'Love Island', emoji: '💕🏝️' },
      // { id: 'epic_dates', name: 'Flirty Dates', emoji: '🫦' },
      // { id: 'taylor_swift', name: 'Celebrity Gossip', emoji: '👩‍🦳' },
      // { id: 'alien_encounters', name: 'Alien Encounters', emoji: '👽' },
      // { id: 'michelin_star_restaurants', name: 'Michelin Star Restaurants', emoji: '🤩' },
      { id: 'luxury_vacations', name: 'Luxury Vacations', emoji: '✈️' },
      // { id: 'full_body_massage', name: 'Healthy Habits', emoji: '💆' },
      { id: 'famous_movies', name: 'Famous Movies', emoji: '🎥' },
      { id: 'famous_songs', name: 'Surfing', emoji: '🏄‍♂️' },
    ];

  const navigate = useNavigate();
  const [question, setQuestion] = useState('');

  const validateInput = () => {
    if (!question.trim() && !conversationHistory.some((item) => item.type === 'answer')) {
      setError('Please type a question or topic in the textarea first.');
      return false;
    }
    return true;
  };
//   const clearError = () => {
//   setError('');
// };

  const [answer, setAnswer] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  //const [hideAnswer, setHideAnswer] = useState(false);
  // const [showAnswerButton, setShowAnswerButton] = useState(false);

  const [metaphorCategory, setMetaphorCategory] = useState('');
// const [showSettingsPopup, setShowSettingsPopup] = useState(false);
const [showTooltip, setShowTooltip] = useState(false);
const [showTooltip2, setShowTooltip2] = useState(false);
const [storyTooltip, setStoryTooltip] = useState(false); // Add this line
const [MetaphorTooltip, setMetaphorTooltip] = useState(false); // Add this line
const [rhymeTooltip, setRhymeTooltip] = useState(false); // Add this line

const [QuizTooltip, setQuizTooltip] = useState(false); // Add this line

 // const [memeImage, setMemeImage] = useState(null);

  //const [numQuizQuestions, setNumQuizQuestions] = useState(0);


 // const [metaphorResponse, setMetaphorResponse] = useState('');
//const [mnemonicResponse, setMnemonicResponse] = useState('');
// const lastMessageRef = useRef(null);


 
const glowing = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
`;
 // const [keyConcepts, setKeyConcepts] = useState([]);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [credits, setCredits] = useState(0);
  const [creditsLoaded, setCreditsLoaded] = useState(false);

  const [pdf, setPDF] = useState(null);
  const [pdfName, setPDFName] = useState('');

  //const [isRedPill, setIsRedPill] = useState(false);
 // const [ setSources] = useState('');
// const [loadingSources, setLoadingSources] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [showQuizGame, setShowQuizGame] = useState(false);
  //const [quizGameLoading, setQuizGameLoading] = useState(false);
 // const [loadingMnemonic] = useState(false);

  //const [image, setImage] = useState(null);
  //const [imagePreviewURL, setImagePreviewURL] = useState('');
  const [images, setImages] = useState([]);
  const [imagePreviewURLs, setImagePreviewURLs] = useState([]);
  const [buttonWrapperHeight, setButtonWrapperHeight] = useState(0);
  const buttonWrapperRef = useRef(null);

  //const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  //const [showManageAccountPopup, setShowManageAccountPopup] = useState(false);
  //const [customizeButtonsPopup, setCustomizeButtonsPopup] = useState(false);
  // const fetchButtonSettings = async () => {
  //   if (auth.currentUser) {
  //     const userRef = doc(firestore, 'users', auth.currentUser.uid);
  //     const userDoc = await getDoc(userRef);
  //     if (userDoc.exists()) {
  //       const userData = userDoc.data();
  //       if (userData.buttonSettings) {
  //         setButtonSettings(userData.buttonSettings);
  //       }
  //     }
  //   }
  // };


  const GlowingEffect = styled.div`
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: linear-gradient(45deg, #E0FFFF, #ADD8E6);
  opacity: 0.6;
  z-index: -1;
  animation: ${glowing} 10s linear infinite;
`;



 const handleImageUpload = async (event) => {
  const newFiles = Array.from(event.target.files);
  if (images.length + newFiles.length > 3) {
    alert('You can only upload up to 3 images at a time.');
    return;
  }

  setImages([...images, ...newFiles]);

  const previewURLs = await Promise.all(
    newFiles.map((file) => URL.createObjectURL(file))
  );
  setImagePreviewURLs([...imagePreviewURLs, ...previewURLs]);
};
 

  const handleImageRemove = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviewURLs((prevURLs) => prevURLs.filter((_, i) => i !== index));
  };

  const Slideover = ({ show, onClose }) => {
    const handleOutsideClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };
  
    return (
      <div onClick={handleOutsideClick} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999 }}>
        <div style={{ direction: 'rtl', width: '100%' }}>
          <ResizableBox width={200} height={Infinity} axis="x" resizeHandles={['e']}>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: show ? 0 : '-50%', // Changed from 'right' to 'left'
                width: '69%',
                height: '100%',
                background: 'white',
                transition: 'left 0.3s ease-in-out', // Changed from 'right' to 'left'
                zIndex: 1000,
                overflowY: 'auto',
                direction: 'ltr', // Reset the text direction inside the Slideover
                padding: '10px', // Add padding inside the Slideover
              }}
            >
              {/* <button onClick={onClose}></button> */}
              <MySuperAnswers />
            </div>
          </ResizableBox>
        </div>
      </div>
    );
  };

  // const startQuizGame = () => {
  //   const numQuestions = parseInt(prompt('Enter the number of quiz questions (1-10):'));
  //   if (numQuestions >= 1 && numQuestions <= 10) {
  //     setQuizLoading(true); // Show loading spinner

  //     setShowQuizGame(false);
  //     setError('');
  
  //     let topic = '';
  //     let relevantHistory = [];
  
  //     if (question.trim() !== '') {
  //       topic = question;
  //     } else {
  //       relevantHistory = conversationHistory.slice(-5);
  //       const lastAnswer = relevantHistory.find((item) => item.type === 'answer')?.content;
  //       if (lastAnswer) {
  //         topic = lastAnswer;
  //       } else {
  //         setError('Please type a question or wait for an answer to generate a quiz game.');
  //         setQuizLoading(false); // Hide loading spinner

  //         return;
  //       }
  //     }
  
  //     axios.post('https://us-central1-aisupertutor.cloudfunctions.net/generateQuizGame', {
  //       topic: topic,
  //       numQuestions: numQuestions,
  //       conversationHistory: relevantHistory
  //     })
  //     .then((response) => {
  //       console.log('Quiz data received:', response.data);
  //       if (Array.isArray(response.data) && response.data.length > 0) {
  //         setQuizData(response.data);
  //         setCurrentQuestionIndex(0);
  //         setScore(0);
  //         setShowResult(false);
  //         setSelectedChoice('');
  //         setShowQuizGame(true);
  //         console.log('Setting showQuizGame to true');  // Add this log

  //       } else {
  //         throw new Error('Invalid quiz data format');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error generating quiz game:', error);
  //       setError('Failed to generate quiz. Please try again. ' + (error.response?.data || error.message));
  //     })
  //     .finally(() => {
  //       setQuizLoading(false); // Hide loading spinner
  //     });
  //   } else {
  //     alert('Invalid number of questions. Please enter a number between 1 and 10.');
  //   }
  // };

  // const startQuizGame = () => {
  //   const numQuestions = parseInt(prompt('Enter the number of quiz questions (1-10):'));
  //   if (numQuestions >= 1 && numQuestions <= 10) {
  //     setQuizLoading(true);
  //     setShowQuizGame(false);
  //     setError('');
  
  //     let topic = question.trim();
  //     let relevantHistory = [];
  
  //     if (topic === '') {
  //       relevantHistory = conversationHistory.slice(-5);
  //       const lastAnswer = relevantHistory.find((item) => item.type === 'answer')?.content;
  //       if (lastAnswer) {
  //         topic = lastAnswer;
  //       }
  //     }
  
  //     if (topic === '') {
  //       setError('Please type a question or wait for an answer to generate a quiz game.');
  //       setQuizLoading(false);
  //       return;
  //     }
  
  //     axios.post('https://us-central1-aisupertutor.cloudfunctions.net/generateQuizGame', {
  //       topic: topic,
  //       numQuestions: numQuestions,
  //       conversationHistory: relevantHistory
  //     })
  //     .then((response) => {
  //       console.log('Quiz data received:', response.data);
  //       if (Array.isArray(response.data) && response.data.length > 0) {
  //         setQuizData(response.data);
  //         setCurrentQuestionIndex(0);
  //         setScore(0);
  //         setShowResult(false);
  //         setSelectedChoice('');
  //         setShowQuizGame(true);
  //         console.log('Setting showQuizGame to true');
  //       } else {
  //         throw new Error('Invalid quiz data format');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error generating quiz game:', error);
  //       setError('Failed to generate quiz. Please try again. ' + (error.response?.data || error.message));
  //     })
  //     .finally(() => {
  //       setQuizLoading(false);
  //     });
  //   } else {
  //     alert('Invalid number of questions. Please enter a number between 1 and 10.');
  //   }
  // };

  const handleQuizPopupSubmit = () => {
    if (numQuestions >= 1 && numQuestions <= 10) {
      startQuizGame();
    } else {
      setError('Please enter a valid number of questions between 1 and 10.');
    }
  };

  
  const startQuizGame = () => {
    if (numQuestions >= 1 && numQuestions <= 10) {
      setQuizLoading(true);
      setShowQuizPopup(false);
      setShowQuizGame(false);
      setError('');
  
      let topic = question.trim();
      let relevantHistory = [];
      if (topic === '') {
        relevantHistory = conversationHistory.slice(-5);
        const lastAnswer = relevantHistory.find((item) => item.type === 'answer')?.content;
        if (lastAnswer) {
          topic = lastAnswer;
        }
      }
      if (topic === '') {
        setError('Please type a question or wait for an answer to generate a quiz game.');
        setQuizLoading(false);
        return;
      }
  
      axios.post('https://us-central1-aisupertutor.cloudfunctions.net/generateQuizGame', {
        topic: topic,
        numQuestions: numQuestions,
        conversationHistory: relevantHistory
      })
      .then((response) => {
        console.log('Quiz data received:', response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setQuizData(response.data);
          setCurrentQuestionIndex(0);
          setScore(0);
          setShowResult(false);
          setSelectedChoice('');
          setShowQuizGame(true);
          console.log('Setting showQuizGame to true');
        } else {
          throw new Error('Invalid quiz data format');
        }
      })
      .catch((error) => {
        console.error('Error generating quiz game:', error);
        setError('Failed to generate quiz. Please try again. ' + (error.response?.data || error.message));
      })
      .finally(() => {
        setQuizLoading(false);
      });
    } else {
      setError('Invalid number of questions. Please enter a number between 1 and 10.');
    }
  };
  
//   const startQuizGame = () => {
//     const numQuestions = parseInt(prompt('Enter the number of quiz questions (1-10):'));
//     if (numQuestions >= 1 && numQuestions <= 10) {
//       setQuizGameLoading(true);
//       setShowQuizGame(true);
//       setNumQuizQuestions(numQuestions);
  
     
//       let topic = '';

// if (question.trim() !== '') {
//   topic = question;
// } else {
//   const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
//   if (lastAnswer) {
//     topic = lastAnswer;
//   } else {
//     setError('Please type a question or wait for an answer to generate a quiz game.');
//     setQuizGameLoading(false);
//     setShowQuizGame(false);
//     return;
//   }
// }
  
 
//       fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateQuizGame', {
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   body: JSON.stringify({
//     topic: topic,
//     numQuestions: numQuestions,
//   }),
// })
//         .then((response) => response.json())
//         .then((quizData) => {
//           // Handle the generated quiz data
//           console.log(quizData);
//           // Update the state or pass the quiz data to the QuizGame component
//         })
//         .catch((error) => {
//           console.error('Error generating quiz game:', error);
//           // Handle the error
//         });
//     } else {
//       alert('Invalid number of questions. Please enter a number between 1 and 10.');
//     }
//   };


  // const handleQuizGameLoaded = () => {
  //   setQuizGameLoading(false);
  //   };

 

  

  // const handleQuizGameComplete = () => {
  //   setShowQuizGame(false);
 
  // };
 
 
  const [buttonSettings, setButtonSettings] = useState({
    quizGame: true,
    metaphor: true,
    biggerVision: true,
    mnemonic: true,
    makeMeme: true,
    academicSources: true,
  });
 
  useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault();
    };

    

    const handlePaste = async (event) => {
      event.preventDefault();
      const clipboardItems = event.clipboardData.items;
      const imageFiles = [];
      let textData = '';
    
      for (const item of clipboardItems) {
        if (item.type.startsWith('image/')) {
          const file = item.getAsFile();
          imageFiles.push(file);
        } else if (item.type === 'text/plain') {
          textData += await new Promise((resolve) => {
            item.getAsString((text) => {
              resolve(text);
            });
          });
        }
      }
    
      if (imageFiles.length > 0) {
        processImages(imageFiles);
      }
    
      if (textData) {
      //  setQuestion((prevQuestion) => prevQuestion + textData);
      }
    };

    const processImages = async (imageFiles) => {
      if (imageFiles.length + images.length > 3) {
        alert('You can only upload up to 3 images at a time.');
        return;
      }

      setImages((prevImages) => [...prevImages, ...imageFiles]);

      const previewURLs = await Promise.all(
        imageFiles.map((file) => URL.createObjectURL(file))
      );
      setImagePreviewURLs((prevURLs) => [...prevURLs, ...previewURLs]);
    };

    const handleDrop = async (event) => {
      event.preventDefault();
      const newFiles = Array.from(event.dataTransfer.files);
      const pdfFiles = newFiles.filter((file) => file.type === 'application/pdf');
      const imageFiles = newFiles.filter((file) => file.type.startsWith('image/'));

      if (imageFiles.length + images.length > 3) {
        alert('You can only upload up to 3 images at a time.');
        return;
      }

      if (imageFiles.length > 0) {
        setPDF(null);
        setPDFName('');
      } else if (pdfFiles.length > 0) {
        setPDF(pdfFiles[0]);
        setPDFName(pdfFiles[0].name);
      }

      setImages((prevImages) => [...prevImages, ...imageFiles]);

      const previewURLs = await Promise.all(
        imageFiles.map((file) => URL.createObjectURL(file))
      );
      setImagePreviewURLs((prevURLs) => [...prevURLs, ...previewURLs]);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('paste', handlePaste);
    };
  }, [images, imagePreviewURLs, question]);



useEffect(() => {
  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        const userData = doc.data();
        if (userData) {
          setCredits(userData.credits || 0);
          setSubscriptionPlan(userData.subscriptionPlan || '');
          setCreditsLoaded(true);
          setStreak(userData.streak || 0);
           setSelectedCharacter(userData.selectedCharacter || 'supertutor');

          if (userData.metaphorCategory) {
            setMetaphorCategory(userData.metaphorCategory);
          }

          if (userData.buttonSettings) {
            setButtonSettings(userData.buttonSettings);
          } else {
            // Set default buttonSettings if not present in Firestore
            const defaultButtonSettings = {
              quizGame: true,
              metaphor: true,
              biggerVision: true,
              mnemonic: true,
              makeMeme: true,
              academicSources: true,
            };
            setButtonSettings(defaultButtonSettings);
            // Save the default buttonSettings to Firestore
            setDoc(userRef, { buttonSettings: defaultButtonSettings }, { merge: true });
          }

          // Automatically show the UpgradePopup for free users
          if (userData.subscriptionPlan === 'free') {
            setShowUpgradePopup(true);
          }

          // Fetch, split, and set user's first name
          const firstName = userData.name ? userData.name.split(" ")[0] : 'User';
          setUserName(firstName);
        }
      });

      // Unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    }
  };

  fetchUserData();
}, []);
   
const onCharacterSelected = (character) => {
  setSelectedCharacter(character);
};


async function getBiggerVision() {
  if (biggerVisionLoading) return;
  if (!validateInput()) return;

  setBiggerVisionLoading(true);

  try {
    const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
    const requestBody = {
      question: question,
      answer: lastAnswer,
    };

    const biggerVisionResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/getBiggerVision', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream',
      },
      body: JSON.stringify(requestBody),
    });

    const reader = biggerVisionResponse.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let biggerVisionText = '';
    setError(''); // Clear the error message

    // Add the biggerVision type to the conversation history
    setConversationHistory((prevHistory) => [
      ...prevHistory,
      { type: 'biggerVision', content: '' },
    ]);

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      const chunk = decoder.decode(value, { stream: true });
      console.log('Received chunk:', chunk); // Debug log

      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.substring(6).trim();
        //  console.log('Parsed data:', data); // Debug log

          if (data === '[DONE]') {
            done = true;
          } else {
            try {
              const parsedData = JSON.parse(data);
             // console.log('JSON parsed data:', parsedData); // Debug log

              // Check if the expected structure exists
              const content = parsedData.content || (parsedData.choices && parsedData.choices[0] && parsedData.choices[0].delta && parsedData.choices[0].delta.content);
              
              if (content) {
                biggerVisionText += content;
                // Update the biggerVision content in the conversation history
                setConversationHistory((prevHistory) => {
                  const lastItem = prevHistory[prevHistory.length - 1];
                  if (lastItem.type === 'biggerVision') {
                    return [
                      ...prevHistory.slice(0, -1),
                      { type: 'biggerVision', content: lastItem.content + content },
                    ];
                  } else {
                    return [
                      ...prevHistory,
                      { type: 'biggerVision', content: content },
                    ];
                  }
                });
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
              console.error('Problematic data:', data);
            }
          }
        }
      }
    }

    // Save the bigger vision to Firestore
    const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
    await addDoc(messagesRef, {
      type: 'biggerVision',
      content: biggerVisionText,
      timestamp: new Date(),
    });

    // Save the big vision response to Firestore
    const lastSuperAnswer = await getLastSuperAnswer();
    if (lastSuperAnswer) {
      await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'superAnswers', lastSuperAnswer.id), {
        bigVision: biggerVisionText,
      });
    }
  } catch (error) {
    console.error('Error fetching bigger vision:', error);
    setError('An error occurred while fetching bigger vision. Please try again.');
  } finally {
    setBiggerVisionLoading(false);
  }
}

async function getMetaphor() {
  setmetaphorLoading(true);

  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('User not logged in');
      setError('User not logged in');
      return;
    }

    const userRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();

    const latestMetaphorCategory = userData.metaphorCategory || '';

    const lastQuestion = question;
    const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;

    const requestBody = {
      question: lastQuestion,
      answer: lastAnswer,
      metaphorCategory: latestMetaphorCategory,
    };

    const metaphorResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/getMetaphor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream',
      },
      body: JSON.stringify(requestBody),
    });

    const reader = metaphorResponse.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let metaphorText = '';

    // Add the metaphor type to the conversation history
    setConversationHistory((prevHistory) => [
      ...prevHistory,
      { type: 'metaphor', content: '' },
    ]);

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      const chunk = decoder.decode(value, { stream: true });
      //console.log('Received chunk:', chunk); // Debug log

      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.substring(6).trim();
         // console.log('Parsed data:', data); // Debug log

          if (data === '[DONE]') {
            done = true;
          } else {
            try {
              const parsedData = JSON.parse(data);
            //  console.log('JSON parsed data:', parsedData); // Debug log

              const content = parsedData.content;
              if (content) {
                metaphorText += content;
                // Update the metaphor content in the conversation history
                setConversationHistory((prevHistory) => {
                  const lastItem = prevHistory[prevHistory.length - 1];
                  if (lastItem.type === 'metaphor') {
                    return [
                      ...prevHistory.slice(0, -1),
                      { type: 'metaphor', content: lastItem.content + content },
                    ];
                  } else {
                    return [
                      ...prevHistory,
                      { type: 'metaphor', content: content },
                    ];
                  }
                });
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
              console.error('Problematic data:', data);
            }
          }
        }
      }
    }

    // Save the metaphor to Firestore
    const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
    await addDoc(messagesRef, {
      type: 'metaphor',
      content: metaphorText,
      timestamp: new Date(),
    });

    // Save the metaphor to Firestore
    const lastSuperAnswer = await getLastSuperAnswer();
    if (lastSuperAnswer) {
      await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'superAnswers', lastSuperAnswer.id), {
        metaphor: metaphorText,
      });
    }
  } catch (error) {
    console.error('Error fetching metaphor:', error);
  } finally {
    setmetaphorLoading(false);
  }
}

const handleQuestionChange = (e) => {
  setQuestion(e.target.value);
};

const handleQuestionPaste = (e) => {
  e.preventDefault(); // Prevent the default paste action
  const textarea = e.target;
  
  const pastedText = e.clipboardData.getData('text/plain');
  const cursorPosition = textarea.selectionStart; // Get the current cursor position
  const textBeforeCursor = textarea.value.substring(0, cursorPosition);
  const textAfterCursor = textarea.value.substring(cursorPosition);
  const newValue = textBeforeCursor + pastedText + textAfterCursor; // Combine text before cursor, pasted text, and text after cursor
  setQuestion(newValue); // Update the state with the new value
  // Update cursor position to be at the end of the pasted text
  textarea.selectionStart = textarea.selectionEnd = cursorPosition + pastedText.length;
};


// async function getMnemonic() {
//   if (mnemonicLoading) return;
//   if (!validateInput()) return;

//   setMnemonicLoading(true);

//   try {
//     const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
//     const requestBody = {
//       question: question,
//       answer: lastAnswer,
//     };

//     const mnemonicResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/getMnemonic', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestBody),
//     });

//     const reader = mnemonicResponse.body.getReader();
//     const decoder = new TextDecoder();
//     let done = false;
//     let mnemonicText = '';
//     setError(''); // Clear the error message
//     // Add the mnemonic type to the conversation history
//     setConversationHistory((prevHistory) => [
//       ...prevHistory,
//       { type: 'mnemonic', content: '' },
//     ]);

//     while (!done) {
//       const { value, done: readerDone } = await reader.read();
//       done = readerDone;
//       const chunk = decoder.decode(value, { stream: true });
//       const lines = chunk.split('\n');

//       for (const line of lines) {
//         if (line.startsWith('data: ')) {
//           const data = line.substring(6);

//           if (data === '[DONE]') {
//             done = true;
//           } else {
//             try {
//               const parsedData = JSON.parse(data);
//               const content = parsedData.choices[0].delta.content;
//               if (content) {
//                 mnemonicText += content;
//                 // Update the mnemonic content in the conversation history
//                 setConversationHistory((prevHistory) => {
//                   const lastItem = prevHistory[prevHistory.length - 1];
//                   if (lastItem.type === 'mnemonic') {
//                     return [
//                       ...prevHistory.slice(0, -1),
//                       { type: 'mnemonic', content: lastItem.content + content },
//                     ];
//                   } else {
//                     return [
//                       ...prevHistory,
//                       { type: 'mnemonic', content: content },
//                     ];
//                   }
//                 });
//               }
//             } catch (error) {
//               console.error('Error parsing JSON:', error);
//             }
//           }
//         }
//       }
//     }

//         // Save the bigger vision to Firestore
//         const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
//         await addDoc(messagesRef, {
//           type: 'mnemonic',
//           content: mnemonicText,
//           timestamp: new Date(),
//         });
//     // Save the mnemonic to Firestore
//     const lastSuperAnswer = await getLastSuperAnswer();
//     if (lastSuperAnswer) {
//       await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'superAnswers', lastSuperAnswer.id), {
//         mnemonic: mnemonicText,
//       });
//     }
//   } catch (error) {
//     console.error('Error fetching mnemonic:', error);
//   } finally {
//     setMnemonicLoading(false);
//    // window.scrollTo(0, document.body.scrollHeight);
//   }
// }
const [buttonsVisible, setButtonsVisible] = useState(true);
const handleToggleButtons = () => {
  setButtonsVisible(!buttonsVisible);
};
const [metaphorLoading, setmetaphorLoading] = useState(false);

const [biggerVisionLoading, setBiggerVisionLoading] = useState(false);
const [rhymeLoading, setRhymeLoading] = useState(false);
// const [tikTokContentLoading, setTikTokContentLoading] = useState(false);


const updateMetaphorCategory = async (newCategory) => {
  const user = auth.currentUser;
  if (!user) return; // Ensure user is logged in

  const userRef = doc(firestore, 'users', user.uid);
  try {
    await updateDoc(userRef, { metaphorCategory: newCategory });
    setMetaphorCategory(newCategory); // Update local state
  } catch (error) {
    console.error("Error updating metaphor category:", error);
  }
};

const MetaphorCategoryPopup = ({ onClose, onSelect, metaphorCategories, updateMetaphorCategory }) => {
  const [customCategory, setCustomCategory] = useState('');

  const handleCustomCategoryChange = (e) => {
    setCustomCategory(e.target.value);
  };

  const handleCustomCategorySubmit = () => {
    if (customCategory.trim() !== '') {
      updateMetaphorCategory(customCategory.trim());
      onSelect({ id: 'custom', name: customCategory.trim() });
      setCustomCategory('');
    }
  };

  useEffect(() => {
    if (buttonWrapperRef.current) {
      setButtonWrapperHeight(buttonWrapperRef.current.offsetHeight);
    }
  }, []); // Remove 'buttonsVisible' from the dependency array

  // useEffect(() => {
  //   if (conversationRef.current) {
  //     conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
  //   }
  // }, []);

  return (
    <div
      className="popup-overlay"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        className="popup-content"
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px',
          maxWidth: '400px',
          width: '100%',
          textAlign: 'center',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        
        <h3 style={{ marginTop: 0 }}>Select Metaphor Category</h3>
        
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {metaphorCategories
            .filter((category) => category.id !== 'choose_your_own')
            .map((category) => (
              <li key={category.id} style={{ marginBottom: '10px' }}>
              <button
                onClick={() => {
                  updateMetaphorCategory(category.name);
                  onSelect(category);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px 20px',
                  fontSize: '16px',
                  backgroundColor: '#f0f0f0',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '100%',
                }}
              >
                <span style={{ marginRight: '10px' }}>{category.emoji}</span>
                {category.name}
              </button>
            </li>
            ))}
          <li style={{ marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={customCategory}
                onChange={handleCustomCategoryChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleCustomCategorySubmit();
                  }
                }}
                placeholder="🎁 Enter your custom category"
                style={{
                  flex: 1,
                  padding: '10px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  textAlign: 'center', // Add this line

                }}
              />
            </div>
          </li>
        </ul>
        <button 
          onClick={onClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
              zIndex: 1000, // Add this line

          }}
        >
          ×
        </button>
        <button
          onClick={onClose}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#f0f0f0',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '20px',
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

// const handleMakeMeme = () => {
//   const textAreaContent = question.trim();
//   const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;

//   if (textAreaContent !== '' || lastAnswer) {
//     setShowMemeTemplatePopup({
//       question: textAreaContent,
//       answer: lastAnswer || ''
//     });
//   } else {
//     setError('Please type a question or topic in the textarea to generate a meme based on.');
//   }
// };
const handleMakeMeme = () => {
  const textAreaContent = question.trim();
  const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;

  if (textAreaContent !== '' || lastAnswer) {
    // Add the user's prompt to the conversation history first
    if (textAreaContent !== '') {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { type: 'question', content: textAreaContent },
      ]);
      
    }
    
    setShowMemeTemplatePopup({
      question: textAreaContent,
      answer: lastAnswer || ''
    });
  } else {
    setError('Please type a question or topic in the textarea to generate a meme based on.');
  }
};

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
    </div>
  );
};

const StorytimePopup = ({ onClose, onSelect }) => {
  const storyCategories = [
    { id: 'romantic_comedy', name: 'Romantic Comedy' },
    { id: 'scifi', name: 'Sci-Fi' },
    { id: 'spy_thriller', name: 'Spy Thriller' },
    { id: 'muscial', name: 'Broadway Musical' },
    { id: 'viral youtube challenge', name: 'Viral youtube video style challenge with a big cash prize' },
    { id: 'snl style skit', name: 'SNL style short comedy skit' },



  ];

  const [customCategory, setCustomCategory] = useState('');

  const handleCustomCategoryChange = (e) => {
    setCustomCategory(e.target.value);
  };

  const handleCustomCategorySubmit = () => {
    if (customCategory.trim() !== '') {
      onSelect({ id: 'custom', name: customCategory.trim() });
      setCustomCategory('');
    }
  };

  return (
    
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        zIndex: 999,
        maxWidth: '90%',
        width: '400px',
        boxSizing: 'border-box',
      }}
    >
      <h2 style={{ marginTop: 0 }}>Select Story Category</h2>
      <button 
          onClick={onClose} 
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
              zIndex: 1000, // Add this line

          }}
        >
          ×
        </button>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
          gap: '10px',
        }}
      >
        {storyCategories.map((category) => (
          <button
            key={category.id}
            onClick={() => onSelect(category)}
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#f0f0f0',
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div style={{ marginTop: '20px' }}>
        <input
          type="text"
          value={customCategory}
          onChange={handleCustomCategoryChange}
          placeholder="Enter custom category"
          style={{
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '100%',
            boxSizing: 'border-box',
          }}
        />
        <button
          onClick={handleCustomCategorySubmit}
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            background: '#32cd32',
                        color: 'white',
            marginTop: '10px',
            cursor: 'pointer',
          }}
        >
          Submit Custom Category
        </button>
      </div>
      {/* <button
        onClick={onClose}
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          backgroundColor: '#f0f0f0',
          color: 'black',
          marginTop: '20px',
          cursor: 'pointer',
        }}
      >
        Close
      </button> */}
    </div>
  );
};

// const handleMemeTemplateSelect = async (template, memeData) => {
//   try {
//     // setLoading(true);
//     setMemeLoading(true);

//     const requestBody = {
//       question: memeData.question,
//       answer: memeData.answer,
//       memeTemplate: template,
//     };

//     const memeResponse = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/askOpenAIMEME', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestBody),
//     });

//     const responseData = await memeResponse.json();
//     if (responseData.memeImage) {
//       // Upload the meme image to Firebase Storage
//       const memeImageRef = ref(storage, `memeImages/${auth.currentUser.uid}/${Date.now()}.jpg`);
//       const memeImageSnapshot = await uploadBytes(memeImageRef, await (await fetch(responseData.memeImage)).blob());
//       const memeImageURL = await getDownloadURL(memeImageSnapshot.ref);

//       // Save the meme image URL to Firestore only if there's an active conversation
//       if (currentConversationId) {
//         const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
//         await addDoc(messagesRef, {
//           type: 'meme',
//           content: memeImageURL,
//           timestamp: new Date(),
//         });
//       } else {
//         // If there's no active conversation, create a new one
//         const user = auth.currentUser;
//         const userRef = doc(firestore, 'users', user.uid);
//         const conversationsRef = collection(userRef, 'conversations');
//         const newConversationRef = await addDoc(conversationsRef, {
//           title: memeData.question || 'New Conversation',
//           createdAt: new Date(),
//         });
//         setCurrentConversationId(newConversationRef.id);

//         // Now add the meme to the new conversation
//         const messagesRef = collection(newConversationRef, 'messages');
//         await addDoc(messagesRef, {
//           type: 'meme',
//           content: memeImageURL,
//           timestamp: new Date(),
//         });
//       }

//       setConversationHistory((prevHistory) => [
//         ...prevHistory,
//         { type: 'meme', content: responseData.memeImage },
//       ]);
//      // window.scrollTo(0, document.body.scrollHeight);
//     } else {
//       setError('Failed to generate meme');
//     }
//   } catch (error) {
//     console.error('Error making meme:', error);
//     setError('An error occurred while making the meme.');
//   } finally {
//    // setLoading(false);
//    setMemeLoading(false);

//     setShowMemeTemplatePopup(false);
//   }
// };
const handleMemeTemplateSelect = async (template, memeData) => {
  try {
    setMemeLoading(true);

    const requestBody = {
      question: memeData.question,
      answer: memeData.answer,
      memeTemplate: template,
    };

    const memeResponse = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/askOpenAIMEME', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const responseData = await memeResponse.json();
    if (responseData.memeImage) {
      // Upload the meme image to Firebase Storage
      const memeImageRef = ref(storage, `memeImages/${auth.currentUser.uid}/${Date.now()}.jpg`);
      const memeImageSnapshot = await uploadBytes(memeImageRef, await (await fetch(responseData.memeImage)).blob());
      const memeImageURL = await getDownloadURL(memeImageSnapshot.ref);

      // Save the meme image URL to Firestore only if there's an active conversation
      if (currentConversationId) {
        const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
        // Add the user's prompt as a separate message if it wasn't added earlier
        if (memeData.question && !conversationHistory.some(item => item.type === 'user' && item.content === memeData.question)) {
          await addDoc(messagesRef, {
            type: 'user',
            content: memeData.question,
            timestamp: new Date(),
          });
        }
        await addDoc(messagesRef, {
          type: 'meme',
          content: memeImageURL,
          timestamp: new Date(),
        });
      } else {
        // If there's no active conversation, create a new one
        const user = auth.currentUser;
        const userRef = doc(firestore, 'users', user.uid);
        const conversationsRef = collection(userRef, 'conversations');
        const newConversationRef = await addDoc(conversationsRef, {
          title: memeData.question || 'New Conversation',
          createdAt: new Date(),
        });
        setCurrentConversationId(newConversationRef.id);

        // Now add the user's prompt and the meme to the new conversation
        const messagesRef = collection(newConversationRef, 'messages');
        if (memeData.question) {
          await addDoc(messagesRef, {
            type: 'user',
            content: memeData.question,
            timestamp: new Date(),
          });
        }
        await addDoc(messagesRef, {
          type: 'meme',
          content: memeImageURL,
          timestamp: new Date(),
        });
      }

      // Update the conversation history
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { type: 'meme', content: responseData.memeImage },
      ]);

      // Add this line to scroll to bottom after updating conversation history
      // setTimeout(scrollToBottom, 100);
    } else {
      setError('Failed to generate meme');
    }
  } catch (error) {
    console.error('Error making meme:', error);
    setError('An error occurred while making the meme.');
  } finally {
    setMemeLoading(false);
    setShowMemeTemplatePopup(false);
  }
};

const downloadMeme = (imageUrl) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = 'generated-meme.jpg';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


// async function getStorytime(category) {
//   try {
//     const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
//     const requestBody = {
//       question: question,
//       answer: lastAnswer,
//       storyCategory: category.name,
//     };

//       // Store the initial request body to use as a prompt later
//       const initialPrompt = `Question: ${question}, Answer: ${lastAnswer}, Category: ${category.name}`;
//       // Trigger image generation based on the story content
//       generateCartoonImage(initialPrompt);
      
//     const storytimeResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/getStorytime', {
//       //const storytimeResponse = await fetch('http://localhost:3001/getStorytime', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestBody),
//     });

//     const reader = storytimeResponse.body.getReader();
//     const decoder = new TextDecoder();
//     let done = false;
//     let storytimeText = '';


//     // Add the storytime type to the conversation history
//     setConversationHistory((prevHistory) => [
//       ...prevHistory,
//       { type: 'storytime', content: '' },
//     ]);

//     while (!done) {
//       const { value, done: readerDone } = await reader.read();
//       done = readerDone;
//       const chunk = decoder.decode(value, { stream: true });
//       const lines = chunk.split('\n');

//       for (const line of lines) {
//         if (line.startsWith('data: ')) {
//           const data = line.substring(6);

//           if (data === '[DONE]') {
//             done = true;
//           } else {
//             try {
//               const parsedData = JSON.parse(data);
//               const content = parsedData.choices[0].delta.content;
//               if (content) {
//                 storytimeText += content;
//                 // Update the storytime content in the conversation history directly
//                 setConversationHistory((prevHistory) => {
//                   const lastItem = prevHistory[prevHistory.length - 1];
//                   if (lastItem.type === 'storytime') {
//                     return [
//                       ...prevHistory.slice(0, -1),
//                       { type: 'storytime', content: lastItem.content + content },
//                     ];
//                   } else {
//                     return [
//                       ...prevHistory,
//                       { type: 'storytime', content: content },
//                     ];
//                   }
//                 });
//               }
//             } catch (error) {
//               console.error('Error parsing JSON:', error);
//               console.error('Problematic data:', data);
//             }
//           }
//         }
//       }
//     }

//      // Save the story content to a state or variable
//   setStoryContent(storytimeText);

//      // Save the storytime to Firestore
//      const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
//      await addDoc(messagesRef, {
//        type: 'storytime',
//        content: storytimeText,
//        timestamp: new Date(),
//      });


//   } catch (error) {
//     console.error('Error fetching storytime:', error);
//   } finally {
//     //window.scrollTo(0, document.body.scrollHeight);
//   }
// }

async function getStorytime(category) {
  try {
    const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
    const requestBody = {
      question: question,
      answer: lastAnswer,
      storyCategory: category.name,
    };

    // Store the initial request body to use as a prompt later
    const initialPrompt = `Question: ${question}, Answer: ${lastAnswer}, Category: ${category.name}`;
    // Trigger image generation based on the story content
    generateCartoonImage(initialPrompt);

    const storytimeResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/getStorytime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/event-stream',
      },
      body: JSON.stringify(requestBody),
    });

    const reader = storytimeResponse.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let storytimeText = '';

    // Add the storytime type to the conversation history
    setConversationHistory((prevHistory) => [
      ...prevHistory,
      { type: 'storytime', content: '' },
    ]);

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      const chunk = decoder.decode(value, { stream: true });
    //  console.log('Received chunk:', chunk); // Debug log

      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.substring(6).trim();
       //   console.log('Parsed data:', data); // Debug log

          if (data === '[DONE]') {
            done = true;
          } else {
            try {
              const parsedData = JSON.parse(data);
            //  console.log('JSON parsed data:', parsedData); // Debug log

              const content = parsedData.content;
              if (content) {
                storytimeText += content;
                // Update the storytime content in the conversation history directly
                setConversationHistory((prevHistory) => {
                  const lastItem = prevHistory[prevHistory.length - 1];
                  if (lastItem.type === 'storytime') {
                    return [
                      ...prevHistory.slice(0, -1),
                      { type: 'storytime', content: lastItem.content + content },
                    ];
                  } else {
                    return [
                      ...prevHistory,
                      { type: 'storytime', content: content },
                    ];
                  }
                });
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
              console.error('Problematic data:', data);
            }
          }
        }
      }
    }

    // Save the story content to a state or variable
    setStoryContent(storytimeText);

    // Save the storytime to Firestore
    const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
    await addDoc(messagesRef, {
      type: 'storytime',
      content: storytimeText,
      timestamp: new Date(),
    });

  } catch (error) {
    console.error('Error fetching storytime:', error);
  }
}


// Function to call the Cloud Function to generate an image
async function generateCartoonImage(storyContent) {
  try {
    const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateCartoonImage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: auth.currentUser.uid,
        prompt: `Make a 2D comic book style superhero vibe cartoon based on this scene: ${storyContent}`
      }),
    });

    const data = await response.json();

    if (data.imageUrl) {
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { type: 'image', content: data.imageUrl },
      ]);
    } else {
      console.error('Error generating image:', data.error);
    }

  } catch (error) {
    console.error('Error generating image:', error);
  }
}

async function getRhyme() {
  if (rhymeLoading) return;
  if (!validateInput()) return;

  setRhymeLoading(true);

  try {
    const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
    const requestBody = {
      question: question,
      answer: lastAnswer,
    };

    const rhymeResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/getRhyme', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const reader = rhymeResponse.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let rhymeText = ''; // Initialize rhymeText variable
    setError(''); // Clear the error message

    if (!currentConversationId) {
      // Create a new conversation document
      const user = auth.currentUser;
      const userRef = doc(firestore, 'users', user.uid);
      const conversationsRef = collection(userRef, 'conversations');
      const newConversationRef = await addDoc(conversationsRef, {
        title: question,
        createdAt: new Date(),
      });
      setCurrentConversationId(newConversationRef.id);
    }

    // Add the rhyme type to the conversation history
    setConversationHistory((prevHistory) => [
      ...prevHistory,
      { type: 'rhyme', content: '' },
    ]);

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.substring(6);

          if (data === '[DONE]') {
            done = true;
          } else {
            try {
              const parsedData = JSON.parse(data);
              const content = parsedData.choices[0].delta.content;
              if (content) {
                rhymeText += content; // Append the rhyme content to rhymeText
                // Update the rhyme content in the conversation history
                setConversationHistory((prevHistory) => {
                  const lastItem = prevHistory[prevHistory.length - 1];
                  if (lastItem.type === 'rhyme') {
                    return [
                      ...prevHistory.slice(0, -1),
                      { type: 'rhyme', content: lastItem.content + content },
                    ];
                  } else {
                    return [
                      ...prevHistory,
                      { type: 'rhyme', content: content },
                    ];
                  }
                });
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
    }

    // Save the rhyme to Firestore
    if (currentConversationId) {
      const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
      await addDoc(messagesRef, {
        type: 'rhyme',
        content: rhymeText,
        timestamp: new Date(),
      });
    }
  } catch (error) {
    console.error('Error fetching rhyme:', error);
  } finally {
    setRhymeLoading(false);
    // window.scrollTo(0, document.body.scrollHeight);
  }
}

async function ask(e) {
  e.preventDefault();

  setError('');
  setQuestion('');
  setAnswer('');
  setShowQuizGame(false);
  setButtonLoading(true);
  setQuestionsAsked(true);
          // Clear the images and image preview URLs
          setImages([]);
          setImagePreviewURLs([]);

  const user = auth.currentUser;
  const userRef = doc(firestore, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  let userData = userDoc.exists() ? userDoc.data() : { streak: 0, lastQuestionDate: new Date(0) };

//new
  let conversationId = currentConversationId;

if (!conversationId) {
  // Create a new conversation document
  const conversationsRef = collection(userRef, 'conversations');
  const newConversationRef = await addDoc(conversationsRef, {
    title: question,
    createdAt: new Date(),
  });
  conversationId = newConversationRef.id;
  setCurrentConversationId(conversationId);
}
  try {
    if (!user) {
      throw new Error("You must be logged in to ask a question.");
    }

    if (!userDoc.exists() || !userDoc.data().hasOwnProperty('streak')) {
      await updateDoc(userRef, {
        streak: 1,
        lastQuestionDate: new Date()
      });
    } else {
      let userData = userDoc.data();
      const currentDate = new Date();
      const lastQuestionDate = userData.lastQuestionDate ? userData.lastQuestionDate.toDate() : null;

      if (!lastQuestionDate || currentDate.toDateString() !== lastQuestionDate.toDateString()) {
        await updateDoc(userRef, {
          streak: userData.streak + 1,
          lastQuestionDate: currentDate,
        });
      }
    }

    if (userData.subscriptionPlan === 'beyond_pro') {
      const currentDate = new Date().toDateString();
      const lastClickDate = userData.lastClickDate ? userData.lastClickDate.toDate().toDateString() : null;

      if (lastClickDate !== currentDate) {
        await updateDoc(userRef, { dailyClicks: 0, lastClickDate: new Date() });
        userData.dailyClicks = 0;
      }

      if (userData.dailyClicks >= 350) {
        setError('System overloaded. Dm @supertutorai for support or please try again after midnight once our systems are available again.');
        setLoading(false);
        return;
      }

      await updateDoc(userRef, { dailyClicks: userData.dailyClicks + 1 });
    }

    await updateDoc(userRef, { metaphorCategory: metaphorCategory });

    if (userData.credits === undefined || isNaN(userData.credits)) {
      await updateDoc(userRef, { credits: 10 });
      userData.credits = 10;
    }

    let pdfURL = null;
    if (pdf) {
      const pdfRef = ref(storage, `pdfs/${Date.now()}_${pdf.name}`);
      await uploadBytes(pdfRef, pdf);
      pdfURL = await getDownloadURL(pdfRef);
    }

    let imageBase64Array = [];
    if (images.length > 0) {
      imageBase64Array = await Promise.all(
        images.map(async (image) => {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          return new Promise((resolve) => {
            reader.onloadend = () => {
              resolve(reader.result.split(',')[1]);
            };
          });
        })
      );
    }

    if (imageBase64Array.length > 0) {
      const visionResponse = await fetch('https://supertutor-server-p5iaicybia-uc.a.run.app/askOpenAIVision', {
     // const visionResponse = await fetch('http://localhost:3001/askOpenAIVision', {
     method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question,
          image_base64_array: imageBase64Array,
          uid: auth.currentUser.uid,
        }),
      });

      const reader = visionResponse.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let receivedText = '';

      const updateReceivedText = (value) => {
     //   console.log('Raw chunk:', value);
        const lines = value.split('\n');
        let updatedText = receivedText;
      
        for (const line of lines) {
         // console.log('Processing line:', line);
          if (line.startsWith('data: ')) {
            const data = line.substring(6);
           // console.log('Extracted data:', data);
      
            if (data === '[DONE]') {
              break;
            }
      
            try {
              const parsedData = JSON.parse(data);
            //  console.log('Parsed data:', parsedData);
              const content = parsedData.content;
              if (content) {
                updatedText += content;
             //   console.log('Updated text:', updatedText);
              }
            } catch (error) {
           //   console.error('JSON parse error:', error);
            //  console.log('Problematic data:', data);
              updatedText += data;
            }
          }
        }
      
        receivedText = updatedText;
        setAnswer(receivedText);
      
        // setConversationHistory((prevHistory) => {
        //   const newHistory = prevHistory.map((item, index) => {
        //     if (index === prevHistory.length - 1 && item.type === 'answer') {
        //       return { ...item, content: updatedText };
        //     }
        //     return item;
        //   });
        //   if (newHistory[newHistory.length - 1].type !== 'answer') {
        //     newHistory.push({ type: 'answer', content: updatedText });
        //   }
        //   return newHistory;
        // });
        setConversationHistory((prevHistory) => {
          const newHistory = prevHistory.map((item, index) => {
            if (index === prevHistory.length - 1 && item.type === 'answer') {
              return { ...item, content: updatedText };
            }
            return item;
          });
          if (newHistory[newHistory.length - 1].type !== 'answer') {
            newHistory.push({ type: 'answer', content: updatedText });
          }
          // if (autoScrollEnabled) {
          //   setTimeout(() => {
          //     // if (autoScrollEnabled) {
          //     //   debouncedScroll();
          //     // }
          //   }, 50);
          // }
            // Call scrollToBottom after the state update is complete
  // setTimeout(scrollToBottom, 0);
  setTimeout(scrollToBottom, 0);

          return newHistory;
        });
      
       // debouncedScroll();
      };

      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { type: 'question', content: question },
        { type: 'images', content: imagePreviewURLs },
      ]);

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        const chunk = decoder.decode(value, { stream: true });
       // console.log('Received chunk:', chunk); // Debug line

        updateReceivedText(chunk);
      }


      setButtonLoading(false);
      const messagesRef = collection(userRef, 'conversations', conversationId, 'messages');

// Save the question
await addDoc(messagesRef, {
  type: 'question',
  content: question,
  timestamp: new Date(),
});

// Save the images
await addDoc(messagesRef, {
  type: 'images',
  content: imagePreviewURLs,
  timestamp: new Date(),
});

// Save the answer
await addDoc(messagesRef, {
  type: 'answer',
  content: receivedText,
  timestamp: new Date(),
});

// if (autoScroll) {
//   window.scrollTo({
//     top: document.body.scrollHeight,
//     behavior: 'smooth'
//   });
// }

    } else {
      const url = pdfURL
     // ? 'http://localhost:3001/askOpenAIPDF'
       ? 'https://supertutor-server-p5iaicybia-uc.a.run.app/askOpenAIPDF'
       // : 'http://localhost:3001/askOpenAI';
        : 'https://supertutor-server-p5iaicybia-uc.a.run.app/askOpenAI';

      const requestBody = {
        question,
        uid: auth.currentUser.uid,
        conversationHistory: conversationHistory
          .filter(item => item.type === 'question' || item.type === 'answer')
          .map(item => ({
            role: item.type === 'question' ? 'user' : 'assistant',
            content: item.content,
          })),
      };

      if (storyContent) {
        requestBody.conversationHistory.push({
          role: 'assistant',
          content: storyContent,
        });
      }

      if (pdfURL) {
        requestBody.pdf_url = pdfURL;
      }

  //    console.log('Request body:', JSON.stringify(requestBody, null, 2));

      const response = await fetch(url, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      //let tempState = '';
      let receivedText = '';

      const updateReceivedText = (value) => {
     //   console.log('Raw chunk:', value); // Log the raw chunk
        const lines = value.split('\n');
        let updatedText = receivedText;
      
        for (const line of lines) {
         // console.log('Processing line:', line); // Log each line
          if (line.startsWith('data: ')) {
            const data = line.substring(6);
            //console.log('Extracted data:', data); // Log the extracted data
      
            if (data === '[DONE]') {
              break;
            }
      
            try {
              const parsedData = JSON.parse(data);
           //   console.log('Parsed data:', parsedData); // Log the parsed data
              const content = parsedData.content;
              if (content) {
                updatedText += content;
             //   console.log('Updated text:', updatedText); // Log the updated text
              }
            } catch (error) {
           //   console.error('JSON parse error:', error);
             // console.log('Problematic data:', data);
              updatedText += data;
            }
          }
        }
      
        receivedText = updatedText;
        setAnswer(receivedText);
      
        setConversationHistory((prevHistory) => {
          const lastItem = prevHistory[prevHistory.length - 1];
          if (lastItem && lastItem.type === 'answer') {
            return [
              ...prevHistory.slice(0, -1),
              { type: 'answer', content: updatedText },
            ];
          } else {
            return [
              ...prevHistory,
              { type: 'answer', content: updatedText },
            ];
          }
        });
      };
      
      // setConversationHistory((prevHistory) => [
      //   ...prevHistory,
      //   { type: 'question', content: question },
      // ]);
      // setConversationHistory((prevHistory) => {
      //   const newHistory = [
      //     ...prevHistory,
      //     { type: 'question', content: question },
      //   ];
      //   setTimeout(() => debouncedScroll(), 0);
      //   return newHistory;
      // });
      // setConversationHistory((prevHistory) => {
      //   const newHistory = [
      //     ...prevHistory,
      //     { type: 'question', content: question },
      //   ];
      //   return newHistory;
      // });
      // debouncedScroll();

      setConversationHistory((prevHistory) => {
        const newHistory = [
          ...prevHistory,
          { type: 'question', content: question },
        ];
      //   setTimeout(() => {
      //     //if (autoScrollEnabled) {
      //      // debouncedScroll();
      //  //   }
      //   }, 50);
        // Call scrollToBottom after the state update is complete
  // setTimeout(scrollToBottom, 0);
  setTimeout(scrollToBottom, 0);

        return newHistory;
      });
      //smoothScroll();

    // Trigger scroll after state update
   // setTimeout(() => debouncedScroll(), 0);
      // if (autoScroll) {
      //   window.scrollTo({
      //     top: document.body.scrollHeight,
      //     behavior: 'smooth'
      //   });
      // }
      
      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        const chunk = decoder.decode(value, { stream: true });
        //console.log('Received chunk:', chunk); // Debug line

        updateReceivedText(chunk);
        
      }

      const messagesRef = collection(userRef, 'conversations', conversationId, 'messages');

// Save the question
await addDoc(messagesRef, {
  type: 'question',
  content: question,
  timestamp: new Date(),
});

// Save the answer
await addDoc(messagesRef, {
  type: 'answer',
  content: receivedText,
  timestamp: new Date(),
});

      setLoading(false);
      setButtonLoading(false);
    }

     // Smooth scrolling


    //window.scrollTo(0, document.body.scrollHeight);
  } catch (error) {
    console.error('An error occurred:', error);
    console.error('Error stack:', error.stack);

    setError('An error occurred. Please try again.');
    setLoading(false);
    setButtonLoading(false);
  }
}

// async function generateTikTokContent() {
//   setTikTokContentLoading(true);
//   try {
//     const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateTikTokContent', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         uid: auth.currentUser.uid,
//         prompt: question.trim() !== '' ? question : conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content,
//       }),
//     });

//     const data = await response.json();
//     if (data.script && data.imageUrls && data.audioUrl) {
//       setConversationHistory((prevHistory) => [
//         ...prevHistory,
//         {
//           type: 'tikTokContent',
//           content: {
//             script: data.script,
//             imageUrls: data.imageUrls,
//             audioUrl: data.audioUrl,
//           }
//         },
//       ]);
//     } else {
//       console.error('Error generating TikTok content:', data.error);
//     }
//   } catch (error) {
//     console.error('Error generating TikTok content:', error);
//   }
//   setTikTokContentLoading(false);
// }


// async function generateImage() {
//   setImageLoading(true);
//   try {
//     const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateImage', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         uid: auth.currentUser.uid,
//         prompt: question.trim() !== '' ? question : conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content,
//       }),
//     });


// async function generateImage(dimension) {
//  // setLoading(true);

//   setImageLoading(true);
//   try {
//     const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateImagenew', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         uid: auth.currentUser.uid,
//         prompt: question.trim() !== '' ? question : conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content,
//         dimension: dimension,
//       }),
//     });

//     const data = await response.json();
//     if (data.filePath) {
//       if (currentConversationId) {
//         const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
//         await addDoc(messagesRef, {
//           type: 'image',
//           filePath: data.filePath,
//           timestamp: new Date(),
//         });
//       }

//       setConversationHistory((prevHistory) => [
//         ...prevHistory,
//         { type: 'image', filePath: data.filePath },
//       ]);
//     } else {
//       console.error('Error generating image:', data.error);
//     }
//   } catch (error) {
//     console.error('Error generating image:', error);
//   }
//   setImageLoading(false);
//  // setLoading(false);

// }
async function generateImage(dimension) {
  setImageLoading(true);
  try {
    const prompt = question.trim() !== '' 
      ? question.trim() 
      : conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content?.trim();

    if (prompt) {
      // Add the prompt to the conversation history
      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { type: 'question', content: prompt },
      ]);
    }

    const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateImagenew', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: auth.currentUser.uid,
        prompt: prompt,
        dimension: dimension,
      }),
    });

    const data = await response.json();
    if (data.filePath) {
      if (currentConversationId) {
        const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
        await addDoc(messagesRef, {
          type: 'image',
          filePath: data.filePath,
          timestamp: new Date(),
        });
      }

      setConversationHistory((prevHistory) => [
        ...prevHistory,
        { type: 'image', filePath: data.filePath },
      ]);

      // Clear the text area after successful image generation
      setQuestion('');
            // Add this line to scroll to bottom after updating conversation history
            // setTimeout(scrollToBottom, 100);
    } else {
      console.error('Error generating image:', data.error);
    }
  } catch (error) {
    console.error('Error generating image:', error);
  }
  setImageLoading(false);
}
// async function generateImage(dimension) {
//   setImageLoading(true);
//   try {
//     const response = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/generateImagenew', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         uid: auth.currentUser.uid,
//         prompt: question.trim() !== '' ? question : conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content,
//         dimension: dimension,
//       }),
//     });

//     const data = await response.json();
//     if (data.imageUrl) {
//       if (currentConversationId) {
//         // Save the image URL to Firestore only if currentConversationId is not null
//         const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
//         await addDoc(messagesRef, {
//           type: 'image',
//           content: data.imageUrl,
//           timestamp: new Date(),
//         });
//       }

//       setConversationHistory((prevHistory) => [
//         ...prevHistory,
//         { type: 'image', content: data.imageUrl },
//       ]);
//     } else {
//       console.error('Error generating image:', data.error);
//     }
//   } catch (error) {
//     console.error('Error generating image:', error);
//   }
//   setImageLoading(false);
// }
  
  // async function getSources() {
  //   if (sourcesLoading) return; // Don't make multiple requests
  //   if (!validateInput()) return;

  //   setSourcesLoading(true);

  //  // setLoading(true);
  //   try {
  //     const lastAnswer = conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content;
  //     const requestBody = {
  //       question: question,
  //       answer: lastAnswer,
  //     };
  
  //     const sourcesResponse = await fetch('https://us-central1-aisupertutor.cloudfunctions.net/getSourcesOpenAIVersion2', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestBody),
  //     });
  
  //     if (sourcesResponse.ok) {
  //       const sourcesText = await sourcesResponse.text();
  //       setError(''); // Clear the error message

  //         // Save the sources to Firestore
  //     const messagesRef = collection(firestore, 'users', auth.currentUser.uid, 'conversations', currentConversationId, 'messages');
  //     await addDoc(messagesRef, {
  //       type: 'sources',
  //       content: sourcesText,
  //       timestamp: new Date(),
  //     });

  //       setConversationHistory((prevHistory) => [
  //         ...prevHistory,
  //         { type: 'sources', content: sourcesText },
  //       ]);
  //     } else {
  //       console.error('Error fetching sources:', sourcesResponse.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching sources:', error);
  //   } finally {
  //     setSourcesLoading(false);
  //    // window.scrollTo(0, document.body.scrollHeight);

  //  //   setLoading(false);
  //   }
  // }

  if (isLoading) {
    return <div>Loading MathJax...</div>;
  }
  return (

    
    
     <MathJaxContext config={mathJaxConfig}>
    <div style={{ textAlign: 'center', maxWidth: '100%', padding: '0 20px', marginBottom: '200px' }}>
    <PinnedGoalsContainer>
        {pinnedGoals.map(goal => (
          <PinnedGoal key={goal.id}>
            {goal.text}
            <GoalActions className="goal-actions">
              <GoalAction onClick={() => handleUnpinGoal(goal.id)}>Unpin</GoalAction>
              <GoalAction onClick={() => {
                const newText = prompt("Edit goal:", goal.text);
                if (newText) handleEditPinnedGoal(goal.id, newText);
              }}>Edit</GoalAction>
            </GoalActions>
          </PinnedGoal>
        ))}
      </PinnedGoalsContainer>
    {/* <h1 style={{ 
  marginTop: '10', 
  marginBottom: '10', 
  fontFamily: 'Arial', 
  color: '#045AC6', 
  fontSize: '30px', 
  paddingTop: '20px' // Add this line



  
}}>Supertutor</h1>     */}
<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
  <img src={logo} alt="Description" width="150" style={{transform: 'rotate(10deg)'}} />

  <img
    src={selectedCharacter === 'supertutor' ? '/supertutorlogo.png' : selectedCharacter === 'profbroc' ? '/profbroc.png' : '/mrmoon.png'}
    alt={selectedCharacter === 'supertutor' ? 'SuperTutor Logo' : selectedCharacter === 'profbroc' ? 'Professor Broc' : 'Mr. Moon'}
    style={{ width: '100%', maxWidth: '140px', margin: '1px 0' }}
  />
</div>

        
  
  {/* <div ref={conversationContainerRef} style={{ flex: 1,  paddingTop: '20px', paddingBottom: '100px' }}> */}
  <div 
        ref={conversationContainerRef} 
        style={{ 
          flex: 1,
          overflowY: 'auto',
          paddingTop: '20px',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 'calc(100vh - 200px)', // Adjust based on your layout
        }}
      >
  {conversationHistory.slice(-displayCount).map((item, index) => (
    <div key={conversationHistory.length - displayCount + index} style={{ marginBottom: '20px' }}>
    {item.type === 'question' && (
  <div
    style={{
      background: '#fcfdff', // Extremely light blue, almost white background

      //  background: '#fff0f0', // Even lighter red background
      // background: 'transparant', // Even lighter red background
      borderRadius: '18px',
      padding: '20px',
      width: 'fit-content', // Adjust this line
      maxWidth: '69%', // Adjust this line
      marginLeft: 'auto', // Add this line
       border: '1px solid #1e90ff', // Light red border
   // border: '2px solid #98FB98', // Light red border
   boxShadow: '0 2px 8px 0 rgba(30, 144, 255, 0.3)',

      backdropFilter: 'blur(5px)',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <GlowingEffect />
    <span>{item.content}</span>
                </div>
              )}




{previewImage && (
    <ImagePreview 
      imageUrl={previewImage} 
      onClose={() => setPreviewImage(null)}
    />
  )}

{item.type === 'images' && (
  <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    {item.content.map((url, imgIndex) => (
      <img 
        key={imgIndex} 
        src={url} 
        alt={`Uploaded ${imgIndex + 1}`} 
        style={{ 
          maxWidth: '100px', 
          maxHeight: '100px',
          cursor: 'pointer' // Add this to indicate it's clickable
        }}
        onClick={() => handleImageClick2(url)} // Add this line
      />
    ))}
  </div>
)}
{item.type === 'answer' && (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src="/supertutorlogo.png" alt="Supertutor" style={{ height: '50px', marginRight: '10px' }} />
    <div
      style={{
        background: '#f0f8ff',
        borderRadius: '18px',
        padding: '20px',
        width: '90%',
        maxWidth: '90%',
        margin: '0 auto',
        border: '2px solid #1e90ff',
        boxShadow: '0 2px 8px 0 rgba(30, 144, 255, 0.3)',
        backdropFilter: 'blur(10px)',
        position: 'relative',
        overflow: 'hidden',
        textAlign: 'left',
      }}
    >
      {processContent(item.content).map((block, blockIndex) => {
        if (block.type === 'code') {
          return (
            <div key={blockIndex} className="code-block-container">
              <CodeBlock language={block.content.language} code={block.content.code} />
            </div>
          );
        } else {
          return (
            <div
              key={blockIndex}
              className="math-content"
              dangerouslySetInnerHTML={{ __html: block.content }}
            />
          );
        }
      })}
<div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
<button
  onClick={() => navigator.clipboard.writeText(item.content)}
  style={{ cursor: 'pointer', marginRight: '10px', display: 'inline-flex', alignItems: 'center', border: 'none', background: 'transparent' }}
  title="Click to copy this text"
>
  📋
  </button>
{!audioGenerated[index] ? (
  <div style={{ position: 'relative', display: 'inline-block' }}>
    <span
      onClick={() => handleGenerateAudio(item.content, index)}
      style={{ cursor: 'pointer', marginRight: '10px', display: 'inline-flex', alignItems: 'center' }}
      title="Click to speak this out loud"
    >
      {audioLoadingStates[index] ? <LoadingSpinner /> : '🔊'}
    </span>
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '18px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        opacity: 0,
        transition: 'opacity 0.2s',
        pointerEvents: 'none',
      }}
      className="audio-tooltip"
    >
      Click for supertutor to read this out loud
    </div>
  </div>
) : (
  <audio
    controls
    style={{ marginLeft: '10px' }}
    onPlay={() => {
      setCurrentAudio(item.audioUrl);
      setPlayingAudioIndex(index);
    }}
    onPause={() => {
      setCurrentAudio(null);
      setPlayingAudioIndex(null);
    }}
    onEnded={() => {
      setCurrentAudio(null);
      setPlayingAudioIndex(null);
    }}
    ref={(audio) => {
      if (audio) {
        if (playingAudioIndex === index) {
          audio.play();
        } else {
          audio.pause();
        }
      }
    }}
  >
    <source src={item.audioUrl} type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
)}
    </div>
  </div>
  </div>

)}


{item.type === 'song' && (
  <div>
    <p><strong>Generated Song Lyrics:</strong></p>
    <p>{item.content.lyrics}</p>
    <audio controls>
      <source src={item.content.audio_url} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
)}

{/* 
{audioGenerated[index] && (
  <audio
    controls
    style={{ marginLeft: '10px' }}
    onPlay={() => {
      setCurrentAudio(item.audioUrl);
      setPlayingAudioIndex(index);
    }}
    onPause={() => {
      setCurrentAudio(null);
      setPlayingAudioIndex(null);
    }}
    onEnded={() => {
      setCurrentAudio(null);
      setPlayingAudioIndex(null);
    }}
    ref={(audio) => {
      if (audio) {
        if (playingAudioIndex === index) {
          audio.play();
        } else {
          audio.pause();
        }
      }
    }}
  >
    <source src={item.audioUrl} type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
)} */}

{item.type === 'biggerVision' && (
  <div
    style={{
      background: '#f0f8ff',
      borderRadius: '18px',
      padding: '20px',
      width: '69%', // Adjust this value as needed
      maxWidth: '69%', // Adjust this value as needed
      minWidth: '69%', // Adjust this value as needed
      margin: '10px auto',
      border: '2px solid #1e90ff',
     // boxShadow: '0 4px 16px 0 rgba(30, 144, 255, 0.3)',
    }}
  >
    <strong>Bigger Vision:</strong>
    <div style={{ marginTop: '10px', textAlign: 'left' }}>
      <ReactMarkdown>{item.content}</ReactMarkdown>
    </div>
  </div>
)}

{item.type === 'storytime' && (
  <div
    style={{
      background: '#eaffbe',
      borderRadius: '18px',
      padding: '20px',
      width: '69%',
      maxWidth: '69%',
      minWidth: '69%',
      margin: '10px auto',
      border: '2px solid #008000',
      //boxShadow: '0 4px 16px 0 rgba(30, 144, 255, 0.3)',
    }}
  >
    <strong>Storytime:</strong>
    <div style={{ marginTop: '10px', textAlign: 'left' }}>
      {item.content.split('\n').map((line, lineIndex) => {
        if (line.startsWith('**') && line.endsWith('**')) {
          return (
            <p
              key={lineIndex}
              style={{
                fontWeight: 'bold',
                fontSize: '1.2em',
                color: 'blue',
                marginBottom: '1em',
              }}
            >
              {line.slice(2, -2)}
            </p>
          );
        } else {
          return (
            <p key={lineIndex}>
              {line.split('"').map((part, partIndex) => {
                if (partIndex % 2 === 1) {
                  return (
                    <span
                      key={partIndex}
                      style={{
                        fontWeight: 'bold',
                        fontSize: '1.1em',
                        color: 'blue',
                        display: 'inline',
                      }}
                    >
                      "{part}"
                    </span>
                  );
                } else {
                  return <span key={partIndex}>{part}</span>;
                }
              })}
            </p>
          );
        }
      })}
    </div>
  </div>
)}
{item.type === 'image' && (
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    margin: '20px 0', 
    position: 'relative',
    width: '100%'
  }}>
    <FirebaseImage 
      filePath={item.filePath}
      alt="Generated Story Cartoon"
      style={{
        width: '40%',
        height: 'auto',
        borderRadius: '10px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
      }}
    />
    {/* <button
      onClick={() => downloadMeme(item.filePath)}
      style={{
        position: 'absolute',
        bottom: '10px',
        right: '30%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
      }}
    >
      &#x21E9;
    </button> */}
  </div>
)}

{item.type === 'metaphor' && (
  <div
    style={{
      background: '#ffffe0',
      borderRadius: '18px',
      padding: '20px',
      width: '69%',
      maxWidth: '69%',
      minWidth: '69%',
      margin: '10px auto',
      border: '2px solid black',
      textAlign: 'left',
    }}
  >
    {item.content}

    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
    {!audioGenerated[index] ? (
  <div style={{ position: 'relative', display: 'inline-block' }}>
    <span
      onClick={() => handleGenerateAudio(item.content, index)}
      style={{ cursor: 'pointer', marginRight: '10px', display: 'inline-flex', alignItems: 'center' }}
      title="Click to speak this out loud"
    >
      {audioLoadingStates[index] ? <LoadingSpinner /> : '🔊'}
    </span>
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '18px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        opacity: 0,
        transition: 'opacity 0.2s',
        pointerEvents: 'none',
      }}
      className="audio-tooltip"
    >
      Click for supertutor to read this out loud
    </div>
        </div>
      ) : (
        <audio
          autoPlay
          controls
          style={{ marginLeft: '10px' }}
          onPlay={() => setCurrentAudio(item.audioUrl)}
          onPause={() => setCurrentAudio(null)}
          onEnded={() => setCurrentAudio(null)}
        >
          <source src={item.audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  </div>
)}


{item.type === 'mnemonic' && (
  <div
    style={{
      background: '#f0f0f0',
      borderRadius: '18px',
      padding: '20px',
      width: '69%', // Adjust this value as needed
      maxWidth: '69%', // Adjust this value as needed
      minWidth: '69%', // Adjust this value as needed
            margin: '10px auto',
      border: '2px solid black',
      textAlign: 'left', // Add this line
    }}
  >
    <strong>Mnemonic:</strong>
    {/* <MathJax> */}
      {item.content.split(/(\*\*[^*]+\*\*)/).map((part, index) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          // Render the text between the double asterisks as bold
          return (
            <strong key={index} style={{ fontSize: '1.3em' }}>
              {part.slice(2, -2)}
            </strong>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    {/* </MathJax> */}
  </div>
)}

{item.type === 'rhyme' && (
  <div className="rhyme-answer" style={{
    background: 'linear-gradient(60deg, #eef7fa 2%, transparent 25%) repeat, linear-gradient(240deg, #eef7fa 5%, transparent 25%) repeat, linear-gradient(60deg, transparent 90%, #cceff7 95%) repeat, linear-gradient(240deg, transparent 75%, #cceff7 95%) repeat, #f0faff',
    borderRadius: '10px',
    border: '2px solid',
    borderColor: 'blue red',
    width: '69%',
    maxWidth: '69%',
    minWidth: '69%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //boxShadow: '0px 0px 10px red, 0px 0px 10px blue',
    padding: '20px'
  }}>
    <strong>SUPERrhyme 🎵:</strong>
    {item.content.split('\n').map((line, index) => (
      <p key={index} style={{ marginBottom: '0.5em' }}>{line}</p>
    ))}
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', justifyContent: 'center' }}>
      {!audioGenerated[index] ? (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <span
            onClick={() => handleGenerateAudio(item.content, index)}
            style={{ cursor: 'pointer', marginRight: '10px', display: 'inline-flex', alignItems: 'center' }}
            title="Click to speak this out loud"
          >
      {audioLoadingStates[index] ? <LoadingSpinner /> : '🔊'}
          </span>
          <div style={{ 
            position: 'absolute',
            top: '100%',
            left: '50%', 
            transform: 'translateX(-50%)',
            background: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '18px',
            whiteSpace: 'nowrap',
            zIndex: 1000,
            opacity: 0,
            transition: 'opacity 0.2s',
            pointerEvents: 'none',
            textAlign: 'center',
          }}>
            Click for supertutor to read this out loud
          </div>
        </div>
      ) : (
        <audio
          autoPlay
          controls
          style={{ marginLeft: '10px' }}
          onPlay={() => setCurrentAudio(item.audioUrl)}
          onPause={() => setCurrentAudio(null)}
          onEnded={() => setCurrentAudio(null)}
        >
          <source src={item.audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  </div>
)}
{/* 
{item.type === 'tikTokContent' && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src="/supertutorlogo.png" alt="Supertutor" style={{ height: '50px', marginRight: '10px' }} />
        <div
          style={{
            background: '#f0f8ff',
            borderRadius: '18px',
            padding: '20px',
            width: '90%',
            maxWidth: '90%',
            margin: '0 auto',
            border: '2px solid #1e90ff',
            boxShadow: '0 2px 8px 0 rgba(30, 144, 255, 0.3)',
            backdropFilter: 'blur(10px)',
            position: 'relative',
            overflow: 'hidden',
            textAlign: 'left',
          }}
        >
          <h3>TikTok-Style Content</h3>
          <div className="script">
            <h4>Script:</h4>
            <p>{item.content.script}</p>
          </div>
          <div className="images" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            <h4>Images:</h4>
            {item.content.imageUrls.map((url, imgIndex) => (
              <img key={imgIndex} src={url} alt={`Scene ${imgIndex + 1}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
            ))}
          </div>
          <div className="audio" style={{ marginTop: '10px' }}>
            <h4>Narration:</h4>
            <audio controls src={item.content.audioUrl}>
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>
    )} */}

{item.type === 'meme' && (
  <div style={{ position: 'relative', marginTop: '20px', display: 'inline-block' }}>
<div style={{ overflow: 'hidden' }}>
  <img 
    src={item.content}
    alt="Generated Meme"
    style={{
      maxWidth: '100%',
      borderRadius: '0px', 
      objectFit: 'cover', 
     transform: 'translateY(3%)', // Adjust this as needed
      height: 'auto',
      width: '100%'
    }} 
  />
</div>
    <button 
      onClick={() => downloadMeme(item.content)}
      style={{
        position: 'absolute', 
        bottom: '10px', 
        right: '10px', 
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#fff', 
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
      }}
    >
      &#x21E9; {/* Unicode for a download symbol */}
    </button>
  </div>
)}

{/* 
        {item.type === 'meme' && (
          <div style={{ marginTop: '20px' }}>
            <img src={item.content} alt="Generated Meme" style={{ maxWidth: '100%' }} />
          </div>
        )} */}

        {item.type === 'sources' && (
          <div
            style={{
              background: '#f0f0f0',
              borderRadius: '18px',
              padding: '20px',
              width: '69%', // Adjust this value as needed
              maxWidth: '69%', // Adjust this value as needed
              minWidth: '69%', // Adjust this value as needed
              margin: '0 auto',
              border: '2px solid black',
            }}
          >
            {item.content.split('\n').map((source, index) => {
              const urlRegex = /(https?:\/\/[^\s)]+)/g;
              const parts = source.split(urlRegex);

              return (
 
                <p key={index} style={{ marginBottom: '1em',textAlign: 'left' }}>
                  {parts.map((part, i) =>
                    urlRegex.test(part) ? (
                      <a key={i} href={part} target="_blank" rel="noopener noreferrer">
                        {part}
                      </a>
                    ) : (
                      part
                    )
                  )}
                </p>
 
              );
            })}
          </div>

          
        )}
      </div>
    ))}
  
</div>

 

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '16px' }}>
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
   
   
  </div>

</div>


{loading && (
  <div className="loading-container">
    <img src="/supertutorlogo.png" alt="Loading..." className="loading-logo" />
  </div>
)}

 
      {error && <p style={{ color: 'red' }}>{error}</p>}
    

      {answer && (
  <div style={{ marginTop: '2px' }}>
    {true ? (
      <p></p>
    ) : (
      <div
        style={{
          background: '#f0fff0',
          borderRadius: '18px',
          padding: '20px',
          maxWidth: '100%',
          margin: '0 auto',
          border: '2px solid black',
        }}
      >
        {answer.split('```').map((block, index) => {
          if (index % 2 === 1) {
            // Code block rendering (unchanged)
            const [language, ...codeLines] = block.split('\n');
            const code = codeLines.join('\n').trim();
            return {
              type: 'code',
              content: { language: language.trim(), code: code }
            };
          } else {
            return block.split('\n').map((paragraph, subIndex) => {
              if (paragraph.match(/!\[.*?\]\(.*?\)/)) {
                // It's an image
                const alt = paragraph.match(/!\[(.*?)\]/)[1];
                const src = paragraph.match(/\((.*?)\)/)[1];
                return (
                  <img
                    key={`${index}-${subIndex}`}
                    src={src}
                    alt={alt}
                    style={{
                      maxWidth: '100%',
                      cursor: 'pointer',
                      marginBottom: '1em'
                    }}
                    onClick={() => handleImageClick2(src)}
                  />
                );
              } else {
                // It's regular text
                return (
                  <p key={`${index}-${subIndex}`} style={{ marginBottom: '1em', textAlign: 'left' }}>
                    {paragraph}
                  </p>
                );
              }
            });
          }
        })}
      </div>
    )}
  </div>
)}

<div style={{ 
  position: 'fixed', 
  bottom: '0', 
  left: '0', 
  right: '0', 
  padding: '3px', 
 // background: 'radial-gradient(circle at center, #fffef0, #fffef0 30%, #fffef0 60%, #fffef0 90%, #fffef0)', 
  //background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 1500%)',
  // background: 'black', // Add this line to set the background to white

  backgroundSize: 'cover',
  paddingBottom: `${buttonWrapperHeight + 10}px`, // Adjust the padding based on the button wrapper height
  zIndex: 1 // Add this line to bring the div to the front
}}>

<div className={`content-wrapper ${questionsAsked ? '' : 'content-wrapper-visible'}`} style={{ 
  position: 'fixed', 
  bottom: '0', 
  left: '0', 
  right: '0', 
  background: 'transparent',
  // background: radial-gradient(circle at center, #e6f7ff, #e6f7ff 30%, #e6f7ff 60%, #e6f7ff 90%, #e6f7ff);  background-size: cover;
  //  background: 'radial-gradient(circle at center, #e6f7ff 0%, #e6f7ff 30%, #e6f7ff 60%, #e6f7ff 90%, #e6f7ff 100%)',

  padding: '3px',
  backgroundSize: 'cover',
  paddingBottom: '1px',
  zIndex: 1
}}>
      <form onSubmit={ask} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '1px' }}>
    {imagePreviewURLs.length > 0 && !pdfName && (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
        {imagePreviewURLs.map((url, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <img src={url} alt={`Uploaded ${index + 1}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
            <button
              onClick={() => handleImageRemove(index)}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                background: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                fontSize: '20px',
              }}
            >
              ×
            </button>
          </div>
        ))}


        
      </div>
    )}

    {pdfName && (
      <div style={{ marginBottom: '10px', position: 'relative' }}>
        <p>Uploaded PDF: {pdfName}</p>
        <button
          onClick={() => {
            setPDF(null);
            setPDFName('');
          }}
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            fontSize: '20px',
          }}
        >
          ×
        </button>
      </div>
    )}

    <div style={{ position: 'relative', width: '80%' }}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <textarea
        autoFocus
          value={question}
          onChange={handleQuestionChange}
          onPaste={handleQuestionPaste}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              ask(e);
            }
          }}
          placeholder={`What can I help you accomplish today?`}
          //  placeholder={`How can I help you today?`}

          style={{
            border: '2px solid #4A90E2',
         //   boxShadow: '0px 0px 19px rgba(74, 144, 226, 0.5)',
            fontSize: '1.2em',
            padding: '10px 140px 10px 10px', // Updated padding
            width: 'calc(100% - 140px)', // Updated width
            height: '60px',
            borderRadius: '15px',
            marginBottom: '-12px',
            resize: 'vertical',
            overflow: 'auto',
            backgroundColor: 'white',
                        color: '#333',
            outline: 'none',
            transition: 'border-color 0.3s',
            fontFamily: 'Arial', // Add this line
            
            maxWidth: '100vw',
            maxHeight: '50vh',
          }}
        />

        <button
          type="button"
          onClick={() => document.querySelector('input[type="file"]').click()}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          style={{
            position: 'absolute',
            top: '50%',
            right: '14px', // Adjusted to ensure it's within the textarea padding
            transform: 'translateY(-50%)',
            fontSize: '1.2em',
            padding: '10px',
            background: 'white',
            color: 'black',
            border: '0px solid black',
          // border: '1px solid #4A90E2',

            borderRadius: '15px',
            cursor: 'pointer',
          }}
        >
          📎
          {showTooltip && (
            <div
              style={{
                position: 'absolute',
                top: '-40px',
                left: '50%',
                transform: 'translateX(-81%)',
                background: 'rgba(0, 0, 0, 0.8)',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                fontSize: '18px',
                whiteSpace: 'nowrap',
              }}
            >
              Drag and drop up to 3 images or a PDF anywhere or click here to choose:)
            </div>
          )}
          <input
            type="file"
            accept="image/*, application/pdf"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file.type === 'application/pdf') {
                setPDF(file);
                setImages([]);
                setImagePreviewURLs([]);
                setPDFName(file.name);
              } else {
                handleImageUpload(e);
                setPDF(null);
                setPDFName('');
              }
            }}
            style={{ display: 'none' }}
            multiple={!pdfName}
          />
        </button>

        <button
  className="startButton"
  type="submit"
  disabled={buttonLoading}
  style={{
    position: 'absolute',
    top: '50%',
    right: '64px',
    transform: 'translateY(-50%)',
    fontSize: '1em',
    padding: '9px 11px',
    background: '#98FB98',
    color: 'black',
     border: '0px solid black',
   // border: '1px solid #4A90E2',

    borderRadius: '40%', // Changed from '15px' to '50%'
    cursor: 'pointer',
  }}
>
{buttonLoading ? (
  <div className="spinner" style={{ width: '16px', height: '16px', border: '6px solid rgba(0, 0, 0, 0.1)', borderLeftColor: '#09f' }}></div>
) : (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4.5C11.6 4.5 11.2 4.7 11 5L5.5 11.5C5.1 12 5.2 12.7 5.7 13.1C6.2 13.5 6.9 13.4 7.3 12.9L11 8.4V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V8.4L16.7 12.9C17.1 13.4 17.8 13.5 18.3 13.1C18.8 12.7 18.9 12 18.5 11.5L13 5C12.8 4.7 12.4 4.5 12 4.5Z" fill="currentColor"/>
  </svg>
)}
</button>


        </div>
        
      </div>
    </form>


    <button
  className="startButton"
  onClick={handleToggleButtons}
  title="Toggle buttons"
  style={{
    position: 'fixed',
    bottom: '4px',
    right: '4px',
    fontSize: '20px', // Reduce this to make the circle smaller
    width: '30px', // Reduce this to make the circle smaller
    height: '30px', // Reduce this to make the circle smaller
    lineHeight: '10px', // Reduce this to make the circle smaller
    textAlign: 'center',
    padding: '0',
    background: 'white',
    color: 'black',
    border: 'none',
    cursor: 'pointer',
    zIndex: 9999,
    transform: buttonsVisible ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center', // Add this to ensure the rotation happens around the center
    transition: 'transform 0.3s ease',
    borderRadius: '50%',
  }}
>
  &#x2304;
</button>

<ColorPickerButton />

<div
  ref={buttonWrapperRef}
  className={`button-wrapper ${buttonsVisible ? 'button-wrapper-visible' : ''}`}
  style={{ height: buttonsVisible ? 'auto' : '0' }}
>

<div style={{ position: 'relative', display: 'inline-block' }}>



{/* 
{buttonSettings.quizGame && (
  <button
    className="startButton"
    onClick={handleQuizClick}
    style={{
      fontSize: '1em',
      padding: '2px 5px',
      marginTop: '5px',
      marginBottom: '-10',
      background: 'transparent',
      marginLeft: '10px',
      color: 'black',
      border: '0px solid #32cd32',
      borderRadius: '15px',
      cursor: 'pointer',
    }}
  >
    <img src={q} alt="Description" height="50" />
  </button>
)}
 */}
 {buttonSettings.quizGame && (
  <button
    className="startButton"
    onClick={handleQuizClick}
    onMouseEnter={() => setQuizTooltip(true)} // Add this line
    onMouseLeave={() => setQuizTooltip(false)} // Add this line
   
    disabled={quizLoading}
    style={{
      fontSize: '1em',
      padding: '2px 5px',
      marginTop: '5px',
      marginBottom: '-10',
      background: 'transparent',
      marginLeft: '10px',
      color: 'black',
      border: '0px solid #32cd32',
      borderRadius: '15px',
      cursor: 'pointer',
    }}
  >
    {quizLoading ? <Spinner /> : <img src={q} alt="Description" height="40" />}
  </button>
)}

{QuizTooltip && (
  <div
    style={{
      position: 'absolute',
      top: '80%',
      left: '40%',
      transform: 'translateX(-50%)',
      background: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1000,
    }}
  >
    Generates a quiz about your prompt
  </div>
)}


<button
  className="startButton"
  onClick={handleStorytimeClick}
  onMouseEnter={() => setStoryTooltip(true)} // Add this line
  onMouseLeave={() => setStoryTooltip(false)} // Add this line
  style={{
    fontSize: '1em',
    padding: '10px 5px',
    marginTop: '10px',
    marginBottom: '-10',
    marginLeft: '10px',
    backgroundColor: 'transparent',
    color: 'black',
    border: '0px solid #008000', // Bright blue
    borderRadius: '15px',
    cursor: 'pointer',
  }}
>
  {/* Storytime */}
  <img src={s} alt="Description" height="40" />

</button>
{storyTooltip && (
  <div
    style={{
      position: 'absolute',
      top: '80%',
      left: '80%',
      transform: 'translateX(-50%)',
      background: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1000,
    }}
  >
    Tells a story and cartoon about your prompt
  </div>
)}


  {quizGameTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        
        borderRadius: '5px',
        fontSize: '18px',  /* Increase font size */
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      Makes a quiz game based on your prompt 
    </div>
  )}
</div>

<div style={{ position: 'relative', display: 'inline-block' }}>
  {buttonSettings.metaphor && (
    <button
      className="startButton"
      onClick={handleMetaphorClick}
      onMouseEnter={() => setMetaphorTooltip(true)} // Add this line
      onMouseLeave={() => setMetaphorTooltip(false)} // Add this line    
      style={{
        fontSize: '1em',
        padding: '10px 5px',
        marginTop: '1px',
        marginLeft: '10px',
        marginBottom: '-10',
        background: 'transparent',
        border: '0px solid #1e90ff',
        borderRadius: '15px',
        cursor: 'pointer',
      }}
    >
      {metaphorLoading ? <Spinner /> : <img src={met} alt="Description" height="40" />}
    </button>
  )}
  {MetaphorTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '80%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      Makes a metaphor about your prompt
    </div>
  )}
</div>
  <div style={{ position: 'relative', display: 'inline-block' }}>
  <button
    className="startButton"
    onClick={getRhyme}
    disabled={rhymeLoading}
    style={{
      fontSize: '1em',
      padding: '10px 5px',
      marginTop: '10px',
      marginBottom: '-10',
      marginLeft: '10px',
      background: 'transparent',
      color: 'black',
      border: '0px solid #9932cc',
      borderRadius: '15px',
      cursor: 'pointer',
     // boxShadow: '0 3px 6px rgba(153, 50, 204, 0.16)',
    }}
     onMouseEnter={() => setRhymeTooltip(true)}
    onMouseLeave={() => setRhymeTooltip(false)}
  >
  {/* {rhymeLoading ? <Spinner /> : 'Rhyme'} */}
  <img src={R} alt="Description" height="40" />

  </button>
  {rhymeTooltip && (
    <div
    style={{
      position: 'absolute',
      top: '80%',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1000,
    }}
    >
      Writes a rhyme about your prompt
    </div>
  )}
</div>


<div style={{ position: 'relative', display: 'inline-block' }}>

{buttonSettings.biggerVision && (
    <button
      className="startButton"
      onClick={getBiggerVision}
      disabled={biggerVisionLoading}
      style={{
        fontSize: '1em',
        padding: '5px 5px',
        marginTop: '0px',
        marginLeft: '10px',
        marginBottom: '-10',
        background: 'transparent',
        color: 'black',
        border: '0px solid #ff4500',
        borderRadius: '15px',
        cursor: 'pointer',
       // boxShadow: '0 3px 6px rgba(255, 69, 0, 0.16)',
      }}
      onMouseEnter={() => setBiggerVisionTooltip(true)}
       onMouseLeave={() => setBiggerVisionTooltip(false)}
    >
  {/* {biggerVisionLoading ? <Spinner /> : 'Bigger Vision'} */}
  <img src={B} alt="Description" height="44" />

    </button>
  )}
    {biggerVisionTooltip && (
    <div
    style={{
      position: 'absolute',
      top: '80%',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1000,
    }}
  >
        Shares bigger vision of why your prompt matters
      </div>
    )}
  </div>

  <div style={{ position: 'relative', display: 'inline-block' }}>
    {/* {buttonSettings.mnemonic && (
      <button
        className="startButton"
        onClick={getMnemonic}
        disabled={mnemonicLoading}
        style={{
          fontSize: '1em',
      //    padding: '10px 10px',
          marginTop: '0px',
          marginLeft: '10px',
          marginBottom: '5',
          background: 'transparent',
          color: 'black',
          border: '0px solid #8a2be2',
          borderRadius: '15px',
          cursor: 'pointer',
        //  boxShadow: '0 3px 6px rgba(138, 43, 226, 0.16)',
        }}
      >
  <img src={M} alt="Description" height="40" />

      </button>
    )} */}
    {/* {mnemonicTooltip && (
      <div
        style={{
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          background: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          fontSize: '18px',
          whiteSpace: 'nowrap',
          zIndex: 1000,
        }}
      >
        Generates a mnemonic device to help you remember your topic 
      </div>
    )} */}
  </div>
  

<div style={{ position: 'relative', display: 'inline-block' }}>
  {buttonSettings.makeMeme && (
    <button
      className="startButton"
      onClick={() => handleMakeMeme(question.trim() !== '' ? question : conversationHistory.slice().reverse().find((item) => item.type === 'answer')?.content)}
      disabled={memeLoading}

      style={{
        fontSize: '1em',
       // padding: '10px 10px',
        marginTop: '0px',
        marginBottom: '-10',
        marginLeft: '10px',
        background: 'transparent',
        color: 'black',
        border: '0px solid #ff00ff',
        borderRadius: '15px',
        cursor: 'pointer',
       // boxShadow: '0 3px 6px rgba(255, 0, 255, 0.16)',
      }}
       onMouseEnter={() => setMemeTooltip(true)}
       onMouseLeave={() => setMemeTooltip(false)}
    >
      {/* Meme */}
      {/* <img src={Me} alt="Description" height="50" /> */}
      {memeLoading ? <Spinner /> : <img src={Me} alt="Description" height="40" />}

    </button>
  )}
  {memeTooltip && (
  <div
  style={{
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    zIndex: 1000,
  }}
>
      Creates a meme based on your prompt 
    </div>
  )}
</div>

<div style={{ position: 'relative', display: 'inline-block' }}>
 
<div style={{ position: 'relative', display: 'inline-block' }}>
  <button
    className="startButton"
    // onClick={generateImage}
    // onClick={() => setShowDimensionPopup(true)}
    onClick={handleImageClick}


    disabled={imageLoading}
    style={{
      fontSize: '1em',
      padding: '9px 9px',
      marginTop: '0px',
      marginBottom: '-10',
      marginLeft: '10px',
      background: 'transparent',
      color: 'black',
      border: '0px solid #ff8c00',
      borderRadius: '15px',
      cursor: 'pointer',
    }}
    onMouseEnter={() => setImageTooltip(true)}
    onMouseLeave={() => setImageTooltip(false)}
  >
    {/* {imageLoading ? <Spinner /> : '🖼️'} */}
    {imageLoading ? <Spinner /> : <img src={imagine} alt="Description" height="42" />}
  </button>
  {imageTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '80%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      Generates an AI image based on your prompt
    </div>
  )}
</div>

{/* <button onClick={generateTikTokContent} disabled={tikTokContentLoading}>
  {tikTokContentLoading ? 'Generating TikTok Content...' : 'Generate TikTok Content'}
</button> */}

  {newChatTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '80%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '18px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      Start new chat
    </div>
  )}
  </div>


  </div>

<style>
  {`
    .button-wrapper * {
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }

    .button-wrapper-visible * {
      opacity: 1;
      pointer-events: auto;
    }
  `}
</style>
          
  <div style={{ position: 'relative', display: 'inline-block' }}>

</div>
</div>


{showSlideover && <Slideover show={showSlideover} onClose={() => setShowSlideover(false)} />}


{showQuizPopup && (
  <QuizPopup
    onClose={() => setShowQuizPopup(false)}
    onSubmit={handleQuizPopupSubmit}
  />
)}


{showStorytimePopup && (
  <StorytimePopup
    onClose={() => setShowStorytimePopup(false)}
    onSelect={(category) => {
      // Check if input is valid
      if (!validateInput()) {
        setShowStorytimePopup(false);
        return;
      }

      // If input is valid, call getStorytime function
      getStorytime(category);
      setError(''); // Clear the error message
      setShowStorytimePopup(false);
    }}
  />
)}
{showTreasureChestPopup && (
  <TreasureChestPopup
    onClose={() => setShowTreasureChestPopup(false)}
    onCharacterSelected={onCharacterSelected}
  />
)}


{showDimensionPopup && (
  <DimensionPopup
  onSelect={(dimension) => {
    setShowDimensionPopup(false);
    generateImage(dimension);
  }}
    onClose={() => setShowDimensionPopup(false)}
  />
)}

{/* {showTreasureChestPopup && (
  <TreasureChestPopup
    onClose={() => setShowTreasureChestPopup(false)}
    onCharacterSelected={onCharacterSelected}
    onOverlayClick={() => setShowTreasureChestPopup(false)}
  />
)} */}
{showMemeTemplatePopup && (
  <MemeTemplatePopup
    onClose={() => setShowMemeTemplatePopup(false)}
    onSelect={handleMemeTemplateSelect}
    memeTemplates={memeTemplates}
    memeText={showMemeTemplatePopup}
  />
)}
{showMetaphorCategoryPopup && (
  <MetaphorCategoryPopup
    onClose={() => setShowMetaphorCategoryPopup(false)}
    onSelect={(category) => {
      if (!validateInput()) {
        setShowMetaphorCategoryPopup(false); // Close the popup
        return;
      }
      setMetaphorCategory(category.name);
      setShowMetaphorCategoryPopup(false);
      setError(''); // Clear the error message

      getMetaphor();
    }}
    metaphorCategories={metaphorCategories}
    updateMetaphorCategory={updateMetaphorCategory}
  />
)}


   {creditsLoaded && (
  <>
    {subscriptionPlan === 'beyond_pro' ? (
      null // Don't display any text for beyond_pro plan
    ) : subscriptionPlan === 'pro' ? (
      <p>
        {credits} available today
      </p>
    ) : (
      <p>
        {/* {credits} credits available */}
        {/* {credits <= 0 && " - Upgrade to get unlimited credits"} */}
      </p>
    )}
  </>
)}

     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '21px' }}>


     <div style={{ position: 'fixed', top: '20px', left: '12px', zIndex: 1000, display: 'flex', flexDirection: 'row-reverse' }}>
     <button
        className="startButton"
        // onClick={() => {
        //   navigate('/my-super-answers');
        //   setShowMenu(false);
        // }}
        onClick={() => setShowSlideover(!showSlideover)}
        onMouseEnter={() => setShowTooltip2(true)}
        onMouseLeave={() => setShowTooltip2(false)}
       
        style={{
          background: 'white',
          border: '0px solid black',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
        //  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          marginLeft: '10px', // Add this line
          marginRight: '10px', // Add this line


        }}
        // title="Conversation History" // Add this line

      >
        <img src={history} alt="history" style={{ width: '20px', height: '20px' }} /> 
        {showTooltip2 && (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      My History
    </div>
  )}
      </button>

  
  </div>

  <div style={{ position: 'fixed', top: '20px', left: '60px', zIndex: 1000 }}>


<button
    className="startButton"
    onClick={handleClearConversation}
    onMouseEnter={() => setClearTooltip(true)}
    onMouseLeave={() => setClearTooltip(false)}
    title="New chat"
  style={{
    background: 'white',
    border: '0px solid red',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  //  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    marginLeft: '0px', // Add this line
    marginRight: '20px', // Changed from marginLeft to marginRight


  }}
>
<img src={newchat} alt="newchat" style={{ width: '20px', height: '20px' }} />
</button>
{clearTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      New Chat
    </div>
  )}

</div>


<div style={{ position: 'fixed', top: '20px', left: '105px', zIndex: 1000 }}>

<div style={{ position: 'relative', display: 'inline-block' }}>
  
  
<button
  className="startButton"
  onClick={handleStartGoals}
  onMouseEnter={() => setGoalsTooltip(true)} // Add this line
  onMouseLeave={() => setGoalsTooltip(false)} // Add this line
  style={{
    background: 'transparent',
    border: '0px solid #98FB98',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '31px',
    height: '31px',
    borderRadius: '50%',
    marginLeft: '7px',
    marginRight: '20px',
  }}
  title="Party Trivia Game"
>
<img src={goals} alt="Goals" style={{ width: '60px', height: '60px', transform: 'rotate(14deg)' }} /></button>
{goalsTooltip && (
  <div
    style={{
      position: 'absolute',
      top: '140%',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1000,
    }}
  >
    My goals
  </div>
)}

</div>
</div>


  <div style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1000, display: 'flex', flexDirection: 'row-reverse' }}>

 
  {showMenu && (
    <div
      style={{
        position: 'absolute',
        top: '50px',
      left: '0px', // Adjust this line
        background: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        padding: '10px',
      }}
    >
   




{showManageAccountPopup && (
  <ManageAccountPopup show={showManageAccountPopup} onClose={handleManageAccountPopupToggle} userName={userName} />
)}


{/* 

  <button
  className="menuButton"
  onClick={() => navigate('/about')}
  // style={{
  //   fontSize: '1em',
  //   padding: '10px 20px',
  //   background: 'white',
  //   color: 'black',
  //   border: '1px solid black',
  //   borderRadius: '15px',
  //   cursor: 'pointer',
  //   marginLeft: '10px',
  //   marginBottom: '5px', // Add this line
  // }} 
  style={{
    width: '100px',
    height: '40px',
    fontSize: '14px',
    padding: '10px',
    background: 'white',
    color: 'black',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '10px 0',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  About 
</button> */}

{showAboutPopup && (
          <AboutPopup show={showAboutPopup} onClose={handleAboutPopupToggle} />
        )}

<button
  className="startButton"
  onClick={() => window.location.href = '/about'} // Navigate to /about when the button is clicked

  style={{
    width: '100px',
    height: '40px',
    fontSize: '14px',
    padding: '10px 20px',
    background: 'white',
    color: 'black',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '10px 0',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
About
</button>


{/* <button
  className="startButton"
  onClick={() => window.location.href = 'mailto:support@aisupertutor.com'} // Open email client when the button is clicked

  style={{
    width: '100px',
    height: '40px',
    fontSize: '14px',
    padding: '10px 20px',
    background: 'white',
    color: 'black',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '10px 0',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
Earn for Referrals </button> */}

<button
  className="startButton"
  onClick={() => window.location.href = 'mailto:support@aisupertutor.com'} // Open email client when the button is clicked

  style={{
    width: '100px',
    height: '40px',
    fontSize: '14px',
    padding: '10px 20px',
    background: 'white',
    color: 'black',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '10px 0',
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  Contact Support
</button>

{/* <div style={{ display: 'flex', justifyContent: 'center' }}>
  <button
    className="startButton"
    onClick={() => window.open('https://www.youtube.com/@supertutorai', '_blank')}
    style={{
      fontSize: '1em',
      padding: '10px 20px',
      background: 'white',
      color: 'black',
      border: '0px solid black',
      borderRadius: '15px',
      cursor: 'pointer',
      marginBottom: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" 
      alt="Instagram"
      style={{
        width: '25px',
        height: 'auto',
      }}
    />
  </button>
</div>


<div style={{ display: 'flex', justifyContent: 'center' }}>
  <button
    className="startButton"
    onClick={() => window.open('https://www.instagram.com/supertutorai', '_blank')}
    style={{
      fontSize: '1em',
      padding: '10px 20px',
      background: 'white',
      color: 'black',
      border: '0px solid black',
      borderRadius: '15px',
      cursor: 'pointer',
      marginBottom: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1200px-Instagram_logo_2016.svg.png"
      alt="Instagram"
      style={{
        width: '20px',
        height: '20px',
      }}
    />
  </button>
</div> */}




<button
  className="startButton"
  onClick={handleManageAccountPopupToggle}
  style={{
    width: '100px',
    height: '40px',
    fontSize: '14px',
    padding: '10px 20px',
    background: 'white',
    color: 'black',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '10px 0',
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
   Account
</button>


<button
  className="startButton"
  onClick={() => signOut(auth)} // Call signOut when the button is clicked
  style={{
    width: '100px',
    height: '40px',
    fontSize: '14px',
    padding: '10px 20px',
    background: 'white',
    color: 'black',
    border: '1px solid #ddd',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '10px 0',
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
  Log Out
</button>
<div style={{ display: 'flex', justifyContent: 'space-around' }}>
  <button
    className="startButton"
    onClick={() => window.open('https://www.youtube.com/@supertutorai', '_blank')}
    style={{
      fontSize: '1em',
      padding: '10px 5px',
      background: 'white',
      color: 'black',
      border: '0px solid black',
      borderRadius: '15px',
      cursor: 'pointer',
      marginBottom: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png" 
      alt="YouTube"
      style={{
        width: '25px',
        height: 'auto',
      }}
    />
  </button>




  <button
    className="startButton"
    onClick={() => window.open('https://www.linkedin.com/company/supertutor/', '_blank')}
    style={{
      fontSize: '1em',
      padding: '10px 5px',
      background: 'white',
      color: 'black',
      border: '0px solid black',
      borderRadius: '15px',
      cursor: 'pointer',
      marginBottom: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" 
      alt="Linkedin"
      style={{
        width: '25px',
        height: 'auto',
      }}
    />
  </button>

  <button
    className="startButton"
    onClick={() => window.open('https://www.instagram.com/supertutorai', '_blank')}
    style={{
      fontSize: '1em',
      padding: '10px 5px',
      background: 'white',
      color: 'black',
      border: '0px solid black',
      borderRadius: '15px',
      cursor: 'pointer',
      marginBottom: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1200px-Instagram_logo_2016.svg.png"
      alt="Instagram"
      style={{
        width: '20px',
        height: '20px',
      }}
    />
  </button>
</div>

    </div>
    
  )}

  <button
    className="menuButton"
    onClick={handleMenuToggle}
    style={{
      background: 'white',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      
     // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    }}
  >
    <div
      style={{
        width: '14px',
        height: '1px',
        
        background: 'black',
        margin: '2px 0',
        transition: 'transform 0.3s',
        transform: showMenu ? 'rotate(45deg) translate(3px, 3px)' : 'none',
      }}
    ></div>
    <div
      style={{
        width: '14px',
        height: '1px',
        background: 'black',
        margin: '2px 0',
        transition: 'opacity 0.3s',
        opacity: showMenu ? 0 : 1,
      }}
    ></div>
    <div
      style={{
        width: '14px',
        height: '1px',
        background: 'black',
        
        margin: '2px 0',
        transition: 'transform 0.3s',
        transform: showMenu ? 'rotate(-45deg) translate(3px, -3px)' : 'none',
      }}
    ></div>
  </button>


      <button
  className="startButton"
  onClick={() => setShowTreasureChestPopup(true)}
  onMouseEnter={() => setRewardsTooltip(true)} // Add this line
  onMouseLeave={() => setRewardsTooltip(false)} // Add this line
  style={{
    background: 'white',
    border: '0px solid blue',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginLeft: '10px',
    marginRight: '10px',
  }}
>
  <img src={star1} alt="Supertutor Streak" style={{ width: '24px', height: '24px', transform: 'rotate(12deg)' }} /> 
</button>
{rewardsTooltip && (
  <div
    style={{
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      background: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      padding: '5px 10px',
      
      borderRadius: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      zIndex: 1000,
    }}
  >
    My rewards
  </div>
)}




<div style={{ position: 'relative', display: 'inline-block' }}>
  <button
    className="startButton"
    onClick={handleStartGame}
    onMouseEnter={() => setGameTooltip(true)} // Add this line
    onMouseLeave={() => setGameTooltip(false)} // Add this line
    style={{
      background: 'transparent',
      border: '0px solid #98FB98',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '31px',
      height: '31px',
      borderRadius: '50%',
      marginLeft: '7px',
      marginRight: '20px',
    }}
  >
    <img src={gametime} alt="Multiplayer game" style={{ width: '70px', height: '70px' }} />
  </button>
  {gameTooltip && (
    <div
      style={{
        position: 'absolute',
        top: '140%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}
    >
      Play multiplayer learning games
    </div>
  )}
</div>


</div>

    
       
      </div>


      </div>

      {/* {quizLoading && <LoadingSpinner />} */}
      {showQuizGame && (
        <>
  <div className="confetti-container">
      <div className="confetti-canvas" id="confetti-canvas"></div>
    </div>
              <div className="quiz-overlay">
            <div className="quiz-popup">
              <button className="close-button" onClick={() => setShowQuizGame(false)}>×</button>
              <div className="quiz-content">
                <h3>Question {currentQuestionIndex + 1}</h3>
                <p>{quizData[currentQuestionIndex].question}</p>
                {quizData[currentQuestionIndex].choices.map((choice, index) => (
                  <button
                    key={index}
                    className={`choice-button ${showResult ? (choice === quizData[currentQuestionIndex].correctAnswer ? 'correct' : 'incorrect') : ''}`}
                    onClick={() => handleChoiceClick(choice)}
                    disabled={showResult}
                  >
                    {choice}
                  </button>
                ))}
                {showResult && (
                  <div>
                    {selectedChoice === quizData[currentQuestionIndex].correctAnswer ? (
                      <p>Correct!</p>
                    ) : (
                      <p>
                        Wrong! The correct answer is: {quizData[currentQuestionIndex].correctAnswer}
                      </p>
                    )}
                    {currentQuestionIndex === quizData.length - 1 ? (
                      <div className="quiz-summary">
                        <h3>Quiz Summary</h3>
                        <div className="summary-content">
                          {quizData.map((question, index) => (
                            <div key={index} className="summary-item">
                              <p><strong>Question {index + 1}:</strong> {question.question}</p>
                              <p><strong>Correct Answer:</strong> {question.correctAnswer}</p>
                            </div>
                          ))}
                        </div>
                        <p>Final Score: {score}/{quizData.length}</p>
                      </div>
                    ) : (
                      <>
                        <button onClick={handleNextQuestion}>Next Question</button>
                      </>
                    )}
                  </div>
                )}
                <p>Score: {score}</p>
              </div>
            </div>
          </div>
          </>

)}


    <UpgradePopup
  show={showUpgradePopup}
  onClose={() => setShowUpgradePopup(false)}
  subscriptionPlan={subscriptionPlan}
/>
    </div>
     </MathJaxContext>



  );
  
}

export default AskQuestion; 