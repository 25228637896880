import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { auth, firestore } from './firebase';
import { useNavigate } from 'react-router-dom';

function MySuperAnswers() {
  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showOptions, setShowOptions] = useState(null);
  const [renameTitle, setRenameTitle] = useState('');

  const createTitle = (content, maxSentences = 2) => {
    if (!content) return 'Untitled';
    const sentences = content.split(/[.!?]+/).filter(sentence => sentence.trim().length > 0);
    const titleSentences = sentences.slice(0, maxSentences);
    let title = titleSentences.join('. ');
    if (sentences.length > maxSentences) {
      title += '...';
    }
    if (!title.endsWith('.') && !title.endsWith('!') && !title.endsWith('?')) {
      title += '.';
    }
    return title;
  };

  useEffect(() => {
    const fetchConversations = async () => {
      if (auth.currentUser) {
        const q = query(collection(firestore, 'users', auth.currentUser.uid, 'conversations'));
        const querySnapshot = await getDocs(q);
        const fetchedConversations = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const firstMessage = data.messages && data.messages.length > 0 ? data.messages[0].content : '';
          let title = data.title;
          if (!title || title === firstMessage) {
            title = createTitle(firstMessage);
            // Update the title in Firestore
            await updateDoc(doc.ref, { title });
          }
          return {
            id: doc.id,
            ...data,
            title
          };
        }));
        const sortedConversations = fetchedConversations.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
        setConversations(sortedConversations);
      }
    };
    fetchConversations();
  }, []);

  const handleConversationClick = (conversationId) => {
    navigate(`/${conversationId}`);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionsClick = (conversationId) => {
    setShowOptions(conversationId);
    setRenameTitle('');
  };

  const handleDeleteClick = async (conversationId) => {
    try {
      await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', conversationId));
      setConversations(conversations.filter((conversation) => conversation.id !== conversationId));
      setShowOptions(null);
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const handleRenameClick = (conversationId) => {
    const conversation = conversations.find((conversation) => conversation.id === conversationId);
    setRenameTitle(conversation.title);
  };

  const handleRenameSubmit = async (e, conversationId) => {
    e.preventDefault();
    try {
      const newTitle = createTitle(renameTitle);
      await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'conversations', conversationId), {
        title: newTitle,
      });
      setConversations(
        conversations.map((conversation) =>
          conversation.id === conversationId ? { ...conversation, title: newTitle } : conversation
        )
      );
      setShowOptions(null);
    } catch (error) {
      console.error('Error renaming conversation:', error);
    }
  };

  const filteredConversations = conversations.filter((conversation) =>
    conversation.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h2>My History</h2>
      <input
        type="text"
        placeholder="Search conversations..."
        value={searchTerm}
        onChange={handleSearch}
        style={{
          width: '69%',
          padding: '10px',
          fontSize: '1em',
          border: '1px solid black',
          borderRadius: '5px',
          marginBottom: '20px',
        }}
      />
      {filteredConversations.map((conversation) => (
        <div 
          key={conversation.id} 
          style={{ 
            marginBottom: '20px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'
          }}
        >
          <span
            onClick={() => handleOptionsClick(conversation.id)}
            style={{ marginRight: '10px', cursor: 'pointer' }}
          >
            ⋮
          </span>
          {showOptions === conversation.id && (
            <div style={{ marginRight: '10px' }}>
              <button onClick={() => handleDeleteClick(conversation.id)}>Delete</button>
              <button onClick={() => handleRenameClick(conversation.id)}>Rename</button>
              {renameTitle && (
                <form onSubmit={(e) => handleRenameSubmit(e, conversation.id)}>
                  <input
                    type="text"
                    value={renameTitle}
                    onChange={(e) => setRenameTitle(e.target.value)}
                  />
                  <button type="submit">Save</button>
                </form>
              )}
            </div>
          )}
          <button
            onClick={() => handleConversationClick(conversation.id)}
            style={{
              fontSize: '1em',
              padding: '10px 20px',
              background: 'white',
              color: 'black',
              border: '1px solid black',
              borderRadius: '15px',
              cursor: 'pointer',
            }}
          >
            {conversation.title}
          </button>
          <span style={{ marginLeft: '10px', fontSize: '0.8em', color: 'gray' }}>
            {conversation.createdAt.toDate().toLocaleString()}
          </span>
        </div>
      ))}
    </div>
  );
}

export default MySuperAnswers;

// import React, { useState, useEffect } from 'react';
// import { collection, query, getDocs } from 'firebase/firestore';
// import { auth, firestore } from './firebase';
// import { useNavigate } from 'react-router-dom';
// import { MathJax, MathJaxContext } from 'better-react-mathjax';

// const mathJaxConfig = {
//   tex: {
//     inlineMath: [['$', '$'], ['\\(', '\\)']]
//   },
//   svg: {
//     fontCache: 'global'
//   }
// };

// function MySuperAnswers() {
//   const navigate = useNavigate();
//   const [conversationHistory, setConversationHistory] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   // useEffect(() => {
//   //   const fetchUserData = async () => {
//   //     const user = auth.currentUser;
//   //     if (user) {
//   //       const userRef = doc(firestore, 'users', user.uid);
//   //       const unsubscribe = onSnapshot(userRef, (doc) => {
//   //         const userData = doc.data();
//   //         if (userData) {
         
  
//   //           // Fetch, split, and set user's first name
//   //           const firstName = userData.name ? userData.name.split(" ")[0] : 'User';
//   //           setUserName(firstName);
//   //         }
//   //       });
  
//   //       // Unsubscribe from the listener when the component unmounts
//   //       return () => unsubscribe();
//   //     }
//   //   };
  
//   //   fetchUserData();
//   // }, []);
//   //     const [userName, setUserName] = useState('');
  
//   useEffect(() => {
//     const fetchConversationHistory = async () => {
//       if (auth.currentUser) {
//         const q = query(collection(firestore, 'users', auth.currentUser.uid, 'superAnswers'));
//         const querySnapshot = await getDocs(q);
//         const fetchedHistory = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//         const sortedHistory = fetchedHistory.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
//         setConversationHistory(sortedHistory);
//       }
//     };
//     fetchConversationHistory();
//   }, []);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const filteredConversationHistory = conversationHistory.filter((item) => {
//     const { question = '', answer = '', memeImageURL = '', generatedImageURL = '' } = item;
//     const lowercaseSearchTerm = searchTerm.toLowerCase();
  
//     return (
//       (typeof question === 'string' && question.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof answer === 'string' && answer.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof memeImageURL === 'string' && memeImageURL.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof generatedImageURL === 'string' && generatedImageURL.toLowerCase().includes(lowercaseSearchTerm))
//     );
//   });

//   return (
//     <MathJaxContext config={mathJaxConfig}>
//       <div>
//         <h2>My Conversation History</h2>
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//           <button
//             onClick={() => navigate('/')}
//             style={{
//               fontSize: '1em',
//               padding: '10px 20px',
//               background: 'white',
//               color: 'black',
//               border: '1px solid black',
//               borderRadius: '15px',
//               cursor: 'pointer',
//             }}
//           >
//             Go Back to Ask Questions
//           </button>
//         </div>
//         <input
//           type="text"
//           placeholder="Search conversation history..."
//           value={searchTerm}
//           onChange={handleSearch}
//           style={{
//             width: '95%',
//             padding: '10px',
//             fontSize: '1em',
//             border: '1px solid black',
//             borderRadius: '5px',
//             marginBottom: '20px',
//           }}
//         />
//         <div style={{ flex: 1, overflowY: 'auto', paddingTop: '20px', paddingBottom: '40px' }}>
        
        
//           {filteredConversationHistory.map((item) => (
//             <div key={item.id} style={{ marginBottom: '20px' }}>
//               {item.memeImageURL && (
//   <div style={{ marginTop: '10px' }}>
//     <img src={item.memeImageURL} alt="Meme" style={{ maxWidth: '100%' }} />
//   </div>
// )}
// {item.generatedImageURL && (
//   <div style={{ marginTop: '10px' }}>
//     <img src={item.generatedImageURL} alt="Generated" style={{ maxWidth: '100%' }} />
//   </div>
// )}
//               {item.question && (
//                 <div
//                   style={{
//                     background: '#fff0f0',
//                     borderRadius: '18px',
//                     padding: '20px',
//                     width: '69%',
//                     maxWidth: '69%',
//                     minWidth: '69%',
//                     margin: '0 auto',
//                     marginBottom: '20px', // Add this line

//                     border: '2px solid #ff6c6c',
//                     backdropFilter: 'blur(5px)',
//                     position: 'relative',
//                     overflow: 'hidden',
//                   }}
//                 >
//                   <strong>My question: </strong>
//                   {/* <strong>{`${userName}: `}</strong> */}
//                   <span>{item.question}</span>
//                 </div>
//               )}
//               {item.answer && (
//                 <div
//                   style={{
//                     background: '#f0f8ff',
//                     borderRadius: '18px',
//                     padding: '20px',
//                     width: '69%',
//                     maxWidth: '69%',
//                     minWidth: '69%',
//                     margin: '0 auto',
//                     border: '2px solid #1e90ff',
//                     boxShadow: '0 2px 8px 0 rgba(30, 144, 255, 0.3)',
//                     backdropFilter: 'blur(10px)',
//                     position: 'relative',
//                     overflow: 'hidden',
//                     whiteSpace: 'pre-wrap',
//                   }}
//                 >
//                   <strong>Supertutor:</strong>
//                   <MathJax>
//                     {typeof item.answer === 'string' ? (
//                       item.answer.split('\n').map((paragraph, index) => (
//                         <p key={index} style={{ marginBottom: '1em', textAlign: 'left' }}>
//                           {paragraph}
//                         </p>
//                       ))
//                     ) : (
//                       item.answer
//                     )}
//                   </MathJax>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </MathJaxContext>
//   );
// }

// export default MySuperAnswers;













// import React, { useState, useEffect } from 'react';
// import { collection, query, getDocs, deleteDoc, doc, updateDoc, addDoc } from 'firebase/firestore';
// import { auth, firestore, storage } from './firebase';
// import { useNavigate } from 'react-router-dom';
// import { ref, deleteObject } from 'firebase/storage';
// import { MathJax, MathJaxContext } from 'better-react-mathjax';

// const mathJaxConfig = {
//   tex: {
//     inlineMath: [['$', '$'], ['\\(', '\\)']]
//   },
//   svg: {
//     fontCache: 'global'
//   }
// };

// function formatAnswer(answer, keyConcepts) {
//   if (typeof answer !== 'string') {
//     return answer;
//   }
//   const words = answer.split(' ');
//   return words.map((word, index) => {
//     const isEmphasized = keyConcepts.some(concept => word.toLowerCase().includes(concept.toLowerCase()));
//     return isEmphasized ? (
//       <span key={index} style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
//         {word + ' '}
//       </span>
//     ) : (
//       word + ' '
//     );
//   });
// }

// function Flashcard({ question, answer, mnemonic, bigVision, metaphor, keyConcepts, memeImageURL, onDelete, onEdit }) {
//   const [isFlipped, setIsFlipped] = useState(false);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedQuestion, setEditedQuestion] = useState(question);
//   const [editedAnswer, setEditedAnswer] = useState(answer);

//   const handleCardClick = () => {
//     if (!isEditing) {
//       setIsFlipped(!isFlipped);
//     }
//   };

//   const handleEdit = () => {
//     setIsEditing(true);
//   };

//   const handleSave = () => {
//     onEdit(editedQuestion, editedAnswer);
//     setIsEditing(false);
//   };

//   const handleShare = () => {
//     const shareData = {
//       title: 'Flashcard',
//       text: `Question: ${question}\nAnswer: ${answer}`,
//     };

//     if (navigator.share) {
//       navigator.share(shareData).catch((error) => {
//         console.error('Error sharing:', error);
//       });
//     } else {
//       copyToClipboard(`Question: ${question}\nAnswer: ${answer}`);
//       alert('Content has been copied to clipboard. You can now paste it anywhere to share.');
//     }
//   };

//   const copyToClipboard = (text) => {
//     const textArea = document.createElement('textarea');
//     textArea.value = text;
//     document.body.appendChild(textArea);
//     textArea.select();
//     document.execCommand('copy');
//     document.body.removeChild(textArea);
//   };


//   return (
//     <div
//       className="flashcard"
//       onClick={handleCardClick}
//       style={{
//         position: 'relative',
//         background: isFlipped ? '#f0fff0' : 'white',
//         borderRadius: '18px',
//         padding: '20px',
//         maxWidth: '100%',
//         margin: '0 auto',
//         border: '2px solid black',
//         cursor: 'pointer',
//         marginBottom: '20px',
//       }}
//     >
//       <div
//         className="icons"
//         style={{
//           position: 'absolute',
//           top: '10px',
//           right: '10px',
//           display: 'flex',
//         }}
//       >
//         <div
//           className="edit-icon"
//           onClick={(e) => {
//             e.stopPropagation();
//             handleEdit();
//           }}
//           style={{
//             fontSize: '1.2em',
//             cursor: 'pointer',
//             marginRight: '10px',
//           }}
//         >
//           ✏️
//         </div>
//         <div
//           className="share-icon"
//           onClick={(e) => {
//             e.stopPropagation();
//             handleShare();
//           }}
//           style={{ fontSize: '1.2em', cursor: 'pointer', marginRight: '10px' }}
//         >
//           📤
//         </div>
//         <div
//           className="delete-icon"
//           onClick={(e) => {
//             e.stopPropagation();
//             onDelete();
//           }}
//           style={{
//             fontSize: '1.2em',
//             cursor: 'pointer',
//           }}
//         >
//           ❌
//         </div>
//       </div>
//       {isFlipped ? (
//   <div>
//     {memeImageURL ? (
//       <MathJax>{question}</MathJax>
//     ) : question === "Storytime" ? (
//       <>
//         {isEditing ? (
//           <textarea
//             value={editedAnswer}
//             onChange={(e) => setEditedAnswer(e.target.value)}
//             style={{ width: '100%', height: '200px' }}
//           />
//         ) : (
//           <>
//             <h4>Storytime:</h4>
//             <div style={{ marginTop: '10px', textAlign: 'left' }}>
//               {answer.split('\n').map((line, lineIndex) => {
//                 if (line.startsWith('**') && line.endsWith('**')) {
//                   return (
//                     <p
//                       key={lineIndex}
//                       style={{
//                         fontWeight: 'bold',
//                         fontSize: '1.2em',
//                         color: 'blue',
//                         marginBottom: '1em',
//                       }}
//                     >
//                       {line.slice(2, -2)}
//                     </p>
//                   );
//                 } else if (line.startsWith('"') && line.endsWith('"')) {
//                   return (
//                     <p
//                       key={lineIndex}
//                       style={{
//                         fontWeight: 'bold',
//                         fontSize: '1.1em',
//                         color: 'blue',
//                         marginBottom: '0.5em',
//                       }}
//                     >
//                       {line}
//                     </p>
//                   );
//                 } else {
//                   return <p key={lineIndex}>{line}</p>;
//                 }
//               })}
//             </div>
//           </>
//         )}
//       </>
//     ) : (
//       <>
//         {isEditing ? (
//           <textarea
//             value={editedAnswer}
//             onChange={(e) => setEditedAnswer(e.target.value)}
//             style={{ width: '100%', height: '200px' }}
//           />
//         ) : (
//           <>
//             <h4>Answer:</h4>
//             <MathJax>
//               {typeof answer === 'string' ? (
//                 answer.split('\n').map((section, index) => (
//                   <p key={index} style={{ marginBottom: '1em' }}>
//                     {keyConcepts ? formatAnswer(section, keyConcepts) : section}
//                   </p>
//                 ))
//               ) : (
//                 answer
//               )}
//             </MathJax>
//             {mnemonic && (
//               <>
//                 <h4>Mnemonic:</h4>
//                 <MathJax><p>{mnemonic}</p></MathJax>
//               </>
//             )}
//             {bigVision && (
//               <>
//                 <h4>Big Vision:</h4>
//                 <MathJax><p>{bigVision}</p></MathJax>
//               </>
//             )}
//             {metaphor && (
//               <>
//                 <h4>Metaphor:</h4>
//                 <MathJax><p>{metaphor}</p></MathJax>
//               </>
//             )}
//           </>
//         )}
//       </>
//     )}
//   </div>
// ) : (
//   <div>
//     {memeImageURL ? (
//       <img src={memeImageURL} alt="Meme" style={{ width: '60%', marginBottom: '10px' }} />
//     ) : (
//       <>
//         {isEditing ? (
//           <input
//             type="text"
//             value={editedQuestion}
//             onChange={(e) => setEditedQuestion(e.target.value)}
//             style={{ width: '100%' }}
//           />
//         ) : (
//           <MathJax><h4>{question}</h4></MathJax>
//         )}
//       </>
//     )}
//   </div>
// )}
// {isEditing && (
//   <button
//     onClick={(e) => {
//       e.stopPropagation();
//       handleSave();
//     }}
//     style={{
//       marginTop: '10px',
//       padding: '5px 10px',
//       borderRadius: '5px',
//       background: 'white',
//       border: '1px solid black',
//       cursor: 'pointer',
//     }}
//   >
//     Save
//   </button>
// )}
//     </div>
//   );
// }

// function MySuperAnswers() {
//   const navigate = useNavigate();
//   const [answers, setAnswers] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const fetchAnswers = async () => {
//       if (auth.currentUser) {
//         const q = query(collection(firestore, 'users', auth.currentUser.uid, 'superAnswers'));
//         const querySnapshot = await getDocs(q);
//         const fetchedAnswers = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//         const sortedAnswers = fetchedAnswers.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
//         setAnswers(sortedAnswers);
//       }
//     };
//     fetchAnswers();
//   }, []);

//   const handleDeleteFlashcard = async (answerId, memeImageURL) => {
//     if (auth.currentUser) {
//       await deleteDoc(doc(firestore, 'users', auth.currentUser.uid, 'superAnswers', answerId));
//       setAnswers(answers.filter((answer) => answer.id !== answerId));

//       if (memeImageURL) {
//         const memeImageRef = ref(storage, memeImageURL);
//         await deleteObject(memeImageRef);
//       }
//     }
//   };

//   const handleEditFlashcard = async (answerId, updatedQuestion, updatedAnswer) => {
//     if (auth.currentUser) {
//       await updateDoc(doc(firestore, 'users', auth.currentUser.uid, 'superAnswers', answerId), {
//         question: updatedQuestion,
//         answer: updatedAnswer,
//       });
//       setAnswers(
//         answers.map((answer) =>
//           answer.id === answerId ? { ...answer, question: updatedQuestion, answer: updatedAnswer } : answer
//         )
//       );
//     }
//   };

//   const handleCreateFlashcard = async () => {
//     if (auth.currentUser) {
//       const newFlashcard = {
//         question: 'This is your new flashcard, fill it in with anything you want to remember better :)',
//         answer: '',
//         timestamp: new Date(),
//       };
//       const docRef = await addDoc(collection(firestore, 'users', auth.currentUser.uid, 'superAnswers'), newFlashcard);
//       setAnswers([{ id: docRef.id, ...newFlashcard }, ...answers]);
//     }
//   };

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const filteredAnswers = answers.filter((answerData) => {
//     const { question = '', answer = '', mnemonic = '', bigVision = '', metaphor = '' } = answerData;
//     const lowercaseSearchTerm = searchTerm.toLowerCase();

//     return (
//       (typeof question === 'string' && question.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof answer === 'string' && answer.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof mnemonic === 'string' && mnemonic.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof bigVision === 'string' && bigVision.toLowerCase().includes(lowercaseSearchTerm)) ||
//       (typeof metaphor === 'string' && metaphor.toLowerCase().includes(lowercaseSearchTerm))
//     );
//   });

//   return (
//     <MathJaxContext config={mathJaxConfig}>
//       <div>
//         <h2>My History Flashcards</h2>
//         <h3>(Click to flip)</h3>
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//           <button
//             onClick={() => navigate('/')}
//             style={{
//               fontSize: '1em',
//               padding: '10px 20px',
//               background: 'white',
//               color: 'black',
//               border: '1px solid black',
//               borderRadius: '15px',
//               cursor: 'pointer',
//             }}
//           >
//             Go Back to Ask Questions
//           </button>
//           <button
//             onClick={handleCreateFlashcard}
//             style={{
//               fontSize: '1em',
//               padding: '10px 20px',
//               background: 'white',
//               color: 'black',
//               border: '1px solid black',
//               borderRadius: '15px',
//               cursor: 'pointer',
//             }}
//           >
//             ➕ New Flashcard
//           </button>
//         </div>
//         <input
//           type="text"
//           placeholder="Search flashcards..."
//           value={searchTerm}
//           onChange={handleSearch}
//           style={{
//             width: '95%',
//             padding: '10px',
//             paddingRight: '20px', // Add this line

//             fontSize: '1em',
//             border: '1px solid black',
//             borderRadius: '5px',
//             marginBottom: '20px',
//             // marginRight: '20px',

//           }}
//         />
//         {filteredAnswers.map((answerData) => (
//           <div key={answerData.id} style={{ position: 'relative' }}>
//             <button
//               onClick={() => handleCreateFlashcard()}
//               style={{
//                 position: 'absolute',
//                 top: '10px',
//                 right: '10px',
//                 fontSize: '1.2em',
//                 background: 'none',
//                 border: 'none',
//                 cursor: 'pointer',
//               }}
//             >
//               ➕
//             </button>
//             <Flashcard
//               question={answerData.question}
//               answer={answerData.answer}
//               mnemonic={answerData.mnemonic}
//               bigVision={answerData.bigVision}
//               metaphor={answerData.metaphor}
//               keyConcepts={answerData.keyConcepts}
//               memeImageURL={answerData.memeImageURL}
//               onDelete={() => handleDeleteFlashcard(answerData.id, answerData.memeImageURL)}
//               onEdit={(updatedQuestion, updatedAnswer) =>
//                 handleEditFlashcard(answerData.id, updatedQuestion, updatedAnswer)
//               }
//             />
//           </div>
//         ))}
//       </div>
//     </MathJaxContext>
//   );
// }

// export default MySuperAnswers;
