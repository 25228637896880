import React, { useRef, useEffect } from 'react';

const AboutPopup = ({ show, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div ref={popupRef} style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '600px',
        width: '100%',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}>
        <p style={{ fontSize: '25px' }}>Have you ever been in class or tried to learn something and the approach to teaching was so boring like "Wah wah wah wah"?</p>
        <img src="/1.png" alt="" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
        <p style={{ fontSize: '25px' }}>In high school I took ap computer science and it was so boring and stressful that I thought I wasn't capable of learning coding...</p>
        <p style={{ fontSize: '18px' }}>Years later I found a tutor who made coding fun and exciting. This ignited my passion for learning and motivated me to advance in that skillset. Now I code everyday and it's super important for my success.</p>
        <p style={{ fontSize: '25px' }}>I created Supertutor originally for myself so that I could continue to learn hard skills in a more exciting way through the power of ai, memes, metaphors, rhymes, humor, gamification and more:)</p>
        <img src="/2.png" alt="" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
        <p style={{ fontSize: '25px' }}>The goal of Supertutor is to arouse enthusiasm for learning</p>
        <img src="/3.png" alt="" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
        <p style={{ fontSize: '25px' }}>Supertutor helps you solve your difficult problems with great accuracy and makes learning fun</p>
        <img src="/4.png" alt="" style={{ width: '100%', borderRadius: '10px', marginBottom: '20px' }} />
        <p style={{ fontSize: '25px' }}>Please enjoy:)</p>
        <p style={{ fontSize: '18px' }}> ~ Adam Jonah ~ founder of Supertutor </p>
        <p style={{ fontSize: '18px' }}> (p.s. please email adam@aisupertutor.org with any feedback, ideas and reviews)</p>

        <button onClick={onClose} style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#f0f0f0',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginTop: '20px',
        }}>Close</button>
      </div>
    </div>
  );
};

export default AboutPopup;
